import { configureStore } from "@reduxjs/toolkit";
import cartSlice from "../Slice/CartSlice";
import ErrorSlice from "../Slice/ErrorSlice";
const store = configureStore({
  reducer: {
    error: ErrorSlice,
    cart: cartSlice,
  },
});
export default store;
