import React from "react";
import styles from "./ContactPage.module.css";
import { Col, Container, Form, Row } from "react-bootstrap";
import { motion } from "framer-motion";
function ContactPage(props) {
  return (
    <Container className={styles.contact}>
      <Row className="d-flex justify-content-center">
        <Col className={`${styles.card} d-flex`} sm={12} md={10} lg={10}>
          <motion.img
            initial={{ x: -200 }}
            whileInView={{ x: 0 }}
            transition={{ duration: 0.7, type: "spring", stiffness: 80 }}
            className="img-fluid d-none d-md-block"
            src={require("../../Assets/Image/contact_img.jpg")}
            alt="contact Page"
          />

          <motion.div
            initial={{ scale: 0.4, opacity: 0 }}
            whileInView={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.4, delay: 0.7 }}
            className={`${styles.contactSec} d-flex flex-column justify-content-around`}
          >
            <p className={styles.title}>
              Fill the form to share your thoughts.
            </p>
            <Form className={styles.form}>
              <Row>
                <Col sm={12}>
                  <Form.Group className={styles.formGroup}>
                    <Form.Label>E-Mail Address</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Enter your e-mail"
                    />
                  </Form.Group>
                </Col>
                <Col sm={12}>
                  <Form.Group className={styles.formGroup}>
                    <Form.Label>Message</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={5}
                      placeholder="write your message"
                    />
                  </Form.Group>
                </Col>
                <Col className="d-flex align-items-center mb-3" sm={12}>
                  <Form.Check type="checkbox" />
                  <p className={styles.checkbox}>
                    By continuing, you agree to MR. Cafe Terms of Use and
                    Privacy Policy.
                  </p>
                </Col>
              </Row>
              <div className="d-flex justify-content-start">
                <button className="button btn-1 m-0 py-2 px-4" type="submit">
                  SEND
                </button>
              </div>
            </Form>
            <div className={styles.location}>
              <Row>
                <Col sm={12} md={6}>
                  <div
                    className={`${styles.contactInfo} d-flex align-items-center`}
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15 12H17C17 10.6739 16.4732 9.40215 15.5355 8.46447C14.5979 7.52678 13.3261 7 12 7V9C12.7956 9 13.5587 9.31607 14.1213 9.87868C14.6839 10.4413 15 11.2044 15 12ZM19 12H21C21 7 16.97 3 12 3V5C15.86 5 19 8.13 19 12ZM20 15.5C18.75 15.5 17.55 15.3 16.43 14.93C16.08 14.82 15.69 14.9 15.41 15.18L13.21 17.38C10.3733 15.9333 8.06671 13.6267 6.62 10.79L8.82 8.59C9.1 8.31 9.18 7.92 9.07 7.57C8.69065 6.41806 8.49821 5.2128 8.5 4C8.5 3.73478 8.39464 3.48043 8.20711 3.29289C8.01957 3.10536 7.76522 3 7.5 3H4C3.73478 3 3.48043 3.10536 3.29289 3.29289C3.10536 3.48043 3 3.73478 3 4C3 8.50868 4.79107 12.8327 7.97918 16.0208C11.1673 19.2089 15.4913 21 20 21C20.2652 21 20.5196 20.8946 20.7071 20.7071C20.8946 20.5196 21 20.2652 21 20V16.5C21 16.2348 20.8946 15.9804 20.7071 15.7929C20.5196 15.6054 20.2652 15.5 20 15.5Z"
                        fill="black"
                      />
                    </svg>

                    <span className="ms-3">09678 - 282 - 828</span>
                  </div>
                  <div
                    className={`${styles.contactInfo} d-flex align-items-center`}
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17 19H7V5H17M17 1H7C5.89 1 5 1.89 5 3V21C5 21.5304 5.21071 22.0391 5.58579 22.4142C5.96086 22.7893 6.46957 23 7 23H17C17.5304 23 18.0391 22.7893 18.4142 22.4142C18.7893 22.0391 19 21.5304 19 21V3C19 2.46957 18.7893 1.96086 18.4142 1.58579C18.0391 1.21071 17.5304 1 17 1Z"
                        fill="black"
                      />
                    </svg>

                    <span className="ms-3">01821 - 422 - 222</span>
                  </div>
                  <div
                    className={`${styles.contactInfo} d-flex align-items-center`}
                  >
                    <svg
                      width="24"
                      height="23"
                      viewBox="0 0 24 23"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.75 3.83345C0.75 3.22345 0.987053 2.63844 1.40901 2.2071C1.83097 1.77577 2.40326 1.53345 3 1.53345H21C21.5967 1.53345 22.169 1.77577 22.591 2.2071C23.0129 2.63844 23.25 3.22345 23.25 3.83345V5.68571L12 12.1533L0.75 5.68571V3.83345Z"
                        fill="black"
                      />
                      <path
                        d="M0.75 7.4444V19.1667C0.75 19.7767 0.987053 20.3617 1.40901 20.7931C1.83097 21.2244 2.40326 21.4667 3 21.4667H21C21.5967 21.4667 22.169 21.2244 22.591 20.7931C23.0129 20.3617 23.25 19.7767 23.25 19.1667V7.4444L12 13.9135L0.75 7.4444Z"
                        fill="black"
                      />
                    </svg>

                    <span className="ms-3">info@abovebd.com</span>
                  </div>
                  <div className={`${styles.contactInfo} d-flex `}>
                    <svg
                      width="36"
                      height="50"
                      viewBox="0 0 36 50"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M18 0C8.325 0 0.5 7.825 0.5 17.5C0.5 30.625 18 50 18 50C18 50 35.5 30.625 35.5 17.5C35.5 7.825 27.675 0 18 0ZM18 23.75C16.3424 23.75 14.7527 23.0915 13.5806 21.9194C12.4085 20.7473 11.75 19.1576 11.75 17.5C11.75 15.8424 12.4085 14.2527 13.5806 13.0806C14.7527 11.9085 16.3424 11.25 18 11.25C19.6576 11.25 21.2473 11.9085 22.4194 13.0806C23.5915 14.2527 24.25 15.8424 24.25 17.5C24.25 19.1576 23.5915 20.7473 22.4194 21.9194C21.2473 23.0915 19.6576 23.75 18 23.75Z"
                        fill="black"
                      />
                    </svg>

                    <span className="ms-3">
                      Above It House# 20, Road# 09 Sector# 11, Dhaka 1230
                    </span>
                  </div>
                </Col>
                <Col sm={12} md={6}>
                  <iframe
                    title="map"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d766.9668629942885!2d90.39227689480414!3d23.877921180638538!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755c4f2bda8544f%3A0x4c2ec30ebae7b65b!2sAbove%20IT!5e0!3m2!1sen!2sbd!4v1683747837499!5m2!1sen!2sbd"
                    width="100%"
                    height="100%"
                    style={{ border: 0, borderRadius: 8 }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  ></iframe>
                </Col>
              </Row>
            </div>
          </motion.div>
        </Col>
      </Row>
    </Container>
  );
}

export default ContactPage;
