import React from "react";
import { Container } from "react-bootstrap";
import Card1 from "../UiElement/DiscountCard/Card1";
import styles from "./Discount.module.css";
function Discount(props) {
  return (
    <div className={styles.contain}>
      <Container>
        <div className={styles.discount}>
          <>
            <Card1 />
            <Card1 />
            <Card1 />
          </>
        </div>
      </Container>
    </div>
  );
}

export default Discount;
