import React from "react";
import { motion } from "framer-motion";
import styles from "./OpeningHour.module.css";
import { Container, Row } from "react-bootstrap";
import Card2 from "../UiElement/Card2/Card2";
function OpeningHour(props) {
  return (
    <Container className={styles.container}>
      <Row>
        <div className={styles.contain}>
          <motion.div
            initial={{ x: "45vw" }}
            whileInView={{ x: 0 }}
            transition={{
              duration: 0.6,
              delay: 0,
              type: "spring",
              stiffness: 80,
            }}
          >
            <Card2
              title="Rest, Relax & Revive!"
              desc="At Vertical people come from all around to rest, relax and revive with good coffee, good company, and great food!"
              img={require("../../Assets/Image/openingHour1.png")}
              style={{ height: "350px" }}
              footer={require("../../Assets/Image/wifi.png")}
            />
          </motion.div>

          <motion.div
            initial={{ x: "23vw" }}
            whileInView={{ x: 0 }}
            transition={{ duration: 0.6, type: "spring", stiffness: 80 }}
          >
            <Card2
              title="Did Someone Say “Coffee”"
              desc="We offer some of the best locally roasted coffee using “Brazilian Santos” beans. Enjoy Dark, Blonde, Jamaican, Italian & Decaf roasts. Also available are our specialty Lattes, Frappes, Mochas, Cappuccinos, Americanos & more …"
              img={require("../../Assets/Image/openingHour2.png")}
              style={{ height: "290px" }}
            />
          </motion.div>
          <Card2
            routine
            img={require("../../Assets/Image/openingHour3.png")}
            style={{ height: "100%" }}
          />
        </div>
      </Row>
    </Container>
  );
}

export default OpeningHour;
