import React from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import styles from "./Login.module.css";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { registerSchema } from "../Util/validation";

function Login() {
  const regForm = useFormik({
    initialValues: {
      name: "",
      email: "",
      password: "",
      confirmPassword: "",
      terms: false,
    },
    validationSchema: registerSchema,
    onSubmit: (values) => {
      // register(values.email, values.password);
      regForm.resetForm();
    },
  });
  return (
    <Container className={styles.login}>
      <Row>
        <Col md={6} sm={12} className={`${styles.img} d-none d-md-block`}>
          <img
            className="img-fluid"
            src={require("../../Assets/Image/login.jpg")}
            alt="Login"
          />
        </Col>
        <Col md={6} sm={12} className={styles.loginSec}>
          <p className={styles.title}>Register</p>
          <div className="d-flex justify-content-between flex-wrap flex-md-nowrap gap-4 mb-4">
            <button
              className={`${styles.socialBtn} d-flex justify-content-center align-items-center`}
            >
              <svg
                className="pe-2"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21 18.5C21 19.881 19.881 21 18.5 21H5.5C4.1195 21 3 19.881 3 18.5V5.5C3 4.119 4.1195 3 5.5 3H18.5C19.881 3 21 4.119 21 5.5V18.5Z"
                  fill="#3F51B5"
                />
                <path
                  d="M17.184 12.5H15.5V19H13V12.5H11.5V10.5H13V9.295C13.001 7.541 13.7295 6.5 15.796 6.5H17.5V8.5H16.3565C15.552 8.5 15.5 8.8 15.5 9.3615V10.5H17.5L17.184 12.5Z"
                  fill="white"
                />
              </svg>
              Continue with Facebook
            </button>
            <button className={styles.socialBtn}>
              <svg
                className="pe-2"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21.8055 10.0415H21V10H12V14H17.6515C16.827 16.3285 14.6115 18 12 18C8.6865 18 6 15.3135 6 12C6 8.6865 8.6865 6 12 6C13.5295 6 14.921 6.577 15.9805 7.5195L18.809 4.691C17.023 3.0265 14.634 2 12 2C6.4775 2 2 6.4775 2 12C2 17.5225 6.4775 22 12 22C17.5225 22 22 17.5225 22 12C22 11.3295 21.931 10.675 21.8055 10.0415Z"
                  fill="#FBC02D"
                />
                <path
                  d="M3.15234 7.3455L6.43784 9.755C7.32684 7.554 9.47984 6 11.9993 6C13.5288 6 14.9203 6.577 15.9798 7.5195L18.8083 4.691C17.0223 3.0265 14.6333 2 11.9993 2C8.15834 2 4.82734 4.1685 3.15234 7.3455Z"
                  fill="#E53935"
                />
                <path
                  d="M12.0002 21.9999C14.5832 21.9999 16.9302 21.0114 18.7047 19.4039L15.6097 16.7849C14.6057 17.5454 13.3577 17.9999 12.0002 17.9999C9.39916 17.9999 7.19066 16.3414 6.35866 14.0269L3.09766 16.5394C4.75266 19.7779 8.11366 21.9999 12.0002 21.9999Z"
                  fill="#4CAF50"
                />
                <path
                  d="M21.8055 10.0415L21.7975 10H21H12V14H17.6515C17.2555 15.1185 16.536 16.083 15.608 16.7855L15.6095 16.7845L18.7045 19.4035C18.4855 19.6025 22 17 22 12C22 11.3295 21.931 10.675 21.8055 10.0415Z"
                  fill="#1565C0"
                />
              </svg>{" "}
              Continue with Google
            </button>
          </div>

          <div className="text-center d-flex justify-content-center align-items-center">
            <div className={styles.centeredElement}></div>
            <div>
              <span className="p-4">OR</span>
            </div>
            <div className={styles.centeredElement}></div>
          </div>
          <Form onSubmit={regForm.handleSubmit} className={styles.form}>
            <Row>
              <Col sm={12}>
                <Form.Group className={styles.formGroup}>
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    name="name"
                    id="name"
                    type="text"
                    onChange={regForm.handleChange}
                    onBlur={regForm.handleBlur}
                    value={regForm.values.name}
                    placeholder="Enter your Name"
                  />
                </Form.Group>
                {regForm.errors.name && regForm.touched.name ? (
                  <p className="warning">{regForm.errors.name}</p>
                ) : null}
              </Col>
              <Col sm={12}>
                <Form.Group className={styles.formGroup}>
                  <Form.Label>E-Mail Address</Form.Label>
                  <Form.Control
                    name="email"
                    id="email"
                    type="email"
                    onChange={regForm.handleChange}
                    onBlur={regForm.handleBlur}
                    value={regForm.values.email}
                    placeholder="Enter your e-mail"
                  />
                </Form.Group>
                {regForm.errors.email && regForm.touched.email ? (
                  <p className="warning">{regForm.errors.email}</p>
                ) : null}
              </Col>
              <Col sm={12}>
                <Form.Group className={styles.formGroup}>
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    name="password"
                    id="password"
                    onChange={regForm.handleChange}
                    onBlur={regForm.handleBlur}
                    value={regForm.values.password}
                    type="password"
                    placeholder="enter your password"
                  />
                </Form.Group>
                {regForm.errors.password && regForm.touched.password ? (
                  <p className="warning">{regForm.errors.password}</p>
                ) : null}
              </Col>

              <Col sm={12}>
                <Form.Group className={styles.formGroup}>
                  <Form.Label>Confirm Password</Form.Label>
                  <Form.Control
                    name="confirmPassword"
                    id="confirmPassword"
                    onChange={regForm.handleChange}
                    onBlur={regForm.handleBlur}
                    value={regForm.values.confirmPassword}
                    type="password"
                    placeholder="Confirm password"
                  />
                </Form.Group>
                {regForm.errors.confirmPassword &&
                regForm.touched.confirmPassword ? (
                  <p className="warning">{regForm.errors.confirmPassword}</p>
                ) : null}
              </Col>

              <Col className="d-flex  mb-3" sm={12}>
                <Form.Check
                  name="terms"
                  id="terms"
                  type="checkbox"
                  onChange={regForm.handleChange}
                  onBlur={regForm.handleBlur}
                  value={regForm.values.terms}
                />
                <p className={styles.checkbox}>
                  By continuing, you agree to MR. Cafe Terms of Use and Privacy
                  Policy.
                </p>
              </Col>
              {regForm.errors.terms && regForm.touched.terms ? (
                <p className="warning">{regForm.errors.terms}</p>
              ) : null}
            </Row>
            <div className="d-flex justify-content-start flex-wrap flex-md-nowrap gap-4 mt-4">
              <button className="button btn-1 m-0 py-2 px-4" type="submit">
                Register
              </button>
            </div>
          </Form>
          <p className={`${styles.sideText} mt-4`}>
            Already Have an Account?{" "}
            <Link className="important" to="/authenticate/login">
              Sign In
            </Link>
          </p>
        </Col>
      </Row>
    </Container>
  );
}

export default Login;
