import React from "react";
import styles from "./AboutPage.module.css";
import { motion } from "framer-motion";
import { Col, Container, Row, Accordion, Form } from "react-bootstrap";

function AboutPage(props) {
  return (
    <div className={styles.about}>
      <Container>
        <Row className={styles.section}>
          <Col lg={6} md={6} sm={12}>
            <motion.div
              initial={{ scale: 0, opacity: 0 }}
              whileInView={{ scale: 1, opacity: 1 }}
              transition={{ duration: 0.4, type: "spring", stiffness: 80 }}
            >
              <img
                className="img-fluid"
                src={require("../../Assets/Image/beans.png")}
                alt="coffee-beans"
              />
            </motion.div>
          </Col>
          <Col lg={6} md={6} sm={12}>
            <motion.div
              initial={{ x: -50, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              transition={{
                duration: 0.4,
                delay: 0.4,
                type: "spring",
                stiffness: 80,
              }}
            >
              <h4 className={styles.title}>Our Story</h4>
              <p className={styles.desc}>
                MR. CAFE - Coffee Roastery was established by founder Andres
                Piñeros in 2012 as a pop-up shop in Villavicencio – Colombia
                with the goal of exclusively serving Colombian coffees. The
                ambition of our new coffee brand was to develop close
                relationships with our coffee farmers and work directly with our
                the people who source our coffees. The relationships we build
                create trust and a mutual standard of excellence. We believe
                transparent and honest connections builds a better world for
                both for us, delivering and enjoying exotic, and rare specialty
                coffee in Los Angeles, and for our amazing farmers who produce
                top-quality coffee in a country that delivers some of the best
                coffees in the world.
              </p>
            </motion.div>
          </Col>
        </Row>
        <Row className={styles.section}>
          <Col lg={6} md={6} sm={12}>
            <motion.div
              initial={{ x: 50, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              transition={{
                duration: 0.4,
                delay: 0.4,
                type: "spring",
                stiffness: 80,
              }}
            >
              <p className={styles.desc}>
                Andres Piñeros moved to Los Angeles CA in early 2013 with the
                sole goal of establishing the brand in a city primed with
                curiosity for premium coffee. He could see the growing swell of
                momentum for specialty coffee and knew the industry had barely
                scratched the surface of potential for world award winning
                Colombian coffees.
              </p>
              <p className={styles.desc}>
                The first official storefront in Redondo Beach CA, opened its
                doors in 2016. Two years later, The Boy & The Bear was able to
                bring another shop to Culver City. By the end of 2019, TB&TB
                opened its third location in beautiful Westchester CA, a
                welcoming neighborhood 5 minutes from LAX. The warm atmosphere
                of our shops bring the palpable magic of the Colombian culture.
                And, at all locations, we are proud to offer the best Colombian
                coffee experience.
              </p>
            </motion.div>
          </Col>
          <Col lg={6} md={6} sm={12}>
            <motion.div
              initial={{ scale: 0, opacity: 0 }}
              whileInView={{ scale: 1, opacity: 1 }}
              transition={{ duration: 0.4, type: "spring", stiffness: 80 }}
            >
              <img
                className="img-fluid"
                src={require("../../Assets/Image/bean_sack.png")}
                alt="beans sack"
              />
            </motion.div>
          </Col>
        </Row>
        <Row className={`${styles.section} `}>
          <Col className="p-md-5" lg={6} md={6} sm={12}>
            <motion.div
              initial={{ x: 50, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              transition={{
                duration: 0.4,
                delay: 0.4,
                type: "spring",
                stiffness: 80,
              }}
            >
              <h4 className={styles.title}>Cameron Williamson</h4>
              <p className={styles.subTitle}>
                Coffee & Meat Chef, South Congress Hotel
              </p>
              <p className={styles.desc}>
                Cameron Williamson, Texas, Chelsea Smith’s upbringing and family
                was all about food and time spent around the table, with her
                parents often cooking at home and spending quality time together
                during family dinners. Smith developed a love of pastry from
                fond memories of baking with her grandmas — Nani, the queen of
                cookies, and Oma, who always had bread and sheet cakes to snack
                on or bake together.
              </p>
              <p className={styles.desc}>
                Cameron Williamson, Texas, Chelsea Smith’s upbringing and family
                was all about food and time spent around the table, with her
                parents often cooking at home and spending quality time together
                during family dinners. Smith developed a love of pastry from
                fond memories of baking with her grandmas — Nani, the queen of
                cookies, and Oma, who always had bread and sheet cakes to snack
                on or bake together.
              </p>
            </motion.div>
          </Col>
          <Col lg={6} md={6} sm={12}>
            <motion.div
              initial={{ scale: 0, opacity: 0 }}
              whileInView={{ scale: 1, opacity: 1 }}
              transition={{ duration: 0.4, type: "spring", stiffness: 80 }}
            >
              <img
                className="img-fluid"
                src={require("../../Assets/Image/caneron.png")}
                alt="beans sack"
              />
            </motion.div>
          </Col>
        </Row>
        <Row className={`${styles.section} `}>
          <Col lg={6} md={6} sm={12}>
            <motion.div
              initial={{ scale: 0, opacity: 0 }}
              whileInView={{ scale: 1, opacity: 1 }}
              transition={{ duration: 0.4, type: "spring", stiffness: 80 }}
            >
              <img
                className="img-fluid py-4"
                src={require("../../Assets/Image/cafe_img.jpg")}
                alt="beans sack"
              />
            </motion.div>
          </Col>
          <Col className="" lg={6} md={6} sm={12}>
            <motion.div
              initial={{ x: -50, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              transition={{
                duration: 0.4,
                delay: 0.4,
                type: "spring",
                stiffness: 80,
              }}
            >
              <h4 className={styles.title}>Brand Identity</h4>

              <p className={styles.desc}>
                The sensibility for unique design and aesthetics have always
                foundational for our specialty coffee brand at Mr.CAFE. Our
                stores are carefully thought - each element intentioned to
                impart the Colombian culture and speak to the rich fauna, flora,
                of its land - as well as the exotic sounds and flavors created
                in the deep of the magical South American rain forests.
              </p>
              <p className={styles.desc}>
                Mr. Cafe brand features metallic copper and natural weathered
                wood. From that foundation, we play with contrast and
                complement. By using variations of TB&TB’s color pallet in each
                store, we keep our imagination active and our creative minds
                entertained. We are extremely proud to present each of our
                stores with a foundational theme that evokes a “feeling” of the
                magical Colombian surrealism. The Black Store, The Grey Store
                and The White Store.
              </p>
            </motion.div>
          </Col>
        </Row>
        <Row className={`${styles.section} `}>
          <Col className={styles.infoSection} sm={12} md={8}>
            <motion.h4
              initial={{ y: -50, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              transition={{
                duration: 0.4,
                type: "spring",
                stiffness: 80,
              }}
              className={styles.title}
            >
              Frequently Asked Questions
            </motion.h4>
            <motion.div
              initial={{ y: 50, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              transition={{
                duration: 0.4,
                type: "spring",
                stiffness: 80,
              }}
            >
              <div className={styles.collapseSec}>
                <p className={styles.subTitle}>About Us</p>
                <Accordion defaultActiveKey="0">
                  <Accordion.Item className={styles.accord} eventKey="1">
                    <Accordion.Header className={styles.accordHeader}>
                      Are you hiring?
                    </Accordion.Header>
                    <Accordion.Body className={styles.accordBody}>
                      <p>
                        Thank you for your interest! Unfortunately, We are not
                        currently hiring at the moment, but We appreciate you
                        reaching out. We wish you the best of luck in your job
                        search and encourage you to keep exploring new
                        opportunities. Who knows, maybe we'll have a chance to
                        work together in the future!
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
              <div className={styles.collapseSec}>
                <p className={styles.subTitle}>Coffee & Drinks, Food</p>
                <Accordion defaultActiveKey="0">
                  <Accordion.Item className={styles.accord} eventKey="1">
                    <Accordion.Header className={styles.accordHeader}>
                      When can I expect the ordered coffee to arrive at my
                      doorstep?
                    </Accordion.Header>
                    <Accordion.Body className={styles.accordBody}>
                      <p>Just 20 minutes if you are in our area.</p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item className={styles.accord} eventKey="2">
                    <Accordion.Header className={styles.accordHeader}>
                      What is the perfect brewing recipe for your coffees?
                    </Accordion.Header>
                    <Accordion.Body className={styles.accordBody}>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur. Excepteur
                      sint occaecat cupidatat non proident, sunt in culpa qui
                      officia deserunt mollit anim id est laborum.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item className={styles.accord} eventKey="3">
                    <Accordion.Header className={styles.accordHeader}>
                      What is the difference between Classic Espresso & Modern
                      Espresso?
                    </Accordion.Header>
                    <Accordion.Body className={styles.accordBody}>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur. Excepteur
                      sint occaecat cupidatat non proident, sunt in culpa qui
                      officia deserunt mollit anim id est laborum.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
              <div className={styles.collapseSec}>
                <p className={styles.subTitle}>Our Cafe</p>
                <Accordion defaultActiveKey="0">
                  <Accordion.Item className={styles.accord} eventKey="1">
                    <Accordion.Header className={styles.accordHeader}>
                      When can I expect the ordered coffee to arrive at my
                      doorstep?
                    </Accordion.Header>
                    <Accordion.Body className={styles.accordBody}>
                      <p>Just 20 minutes if you are in our area.</p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item className={styles.accord} eventKey="2">
                    <Accordion.Header className={styles.accordHeader}>
                      What is the perfect brewing recipe for your coffees?
                    </Accordion.Header>
                    <Accordion.Body className={styles.accordBody}>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur. Excepteur
                      sint occaecat cupidatat non proident, sunt in culpa qui
                      officia deserunt mollit anim id est laborum.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item className={styles.accord} eventKey="3">
                    <Accordion.Header className={styles.accordHeader}>
                      What is the difference between Classic Espresso & Modern
                      Espresso?
                    </Accordion.Header>
                    <Accordion.Body className={styles.accordBody}>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur. Excepteur
                      sint occaecat cupidatat non proident, sunt in culpa qui
                      officia deserunt mollit anim id est laborum.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </motion.div>
          </Col>
        </Row>
        <Row className={`${styles.section} `}>
          <Col className={styles.infoSection} sm={12} md={8}>
            <motion.div
              initial={{ y: -50, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              transition={{
                duration: 0.4,
                type: "spring",
                stiffness: 80,
              }}
            >
              <h4 className={styles.title}>Still Have Questions</h4>
              <p className={styles.subTitle}>
                Please fill out our contact form. We will get back to you as
                <br /> soon as possible.
              </p>
            </motion.div>
          </Col>
          <Col className={styles.infoSection} sm={12} md={8}>
            <motion.div
              initial={{ y: 50, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              transition={{
                duration: 0.4,
                type: "spring",
                stiffness: 80,
              }}
            >
              <Form className={styles.form}>
                <Row>
                  <Col sm={12} md={6}>
                    <Form.Group className={styles.formGroup}>
                      <Form.Label>Full Name</Form.Label>
                      <Form.Control type="text" placeholder="Enter your name" />
                    </Form.Group>
                  </Col>
                  <Col sm={12} md={6}>
                    <Form.Group className={styles.formGroup}>
                      <Form.Label>E-Mail Address</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Enter your e-mail"
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={12} md={6}>
                    <Form.Group className={styles.formGroup}>
                      <Form.Label>Phone Number</Form.Label>
                      <Form.Control
                        type="tel"
                        placeholder="Enter your Number"
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={12} md={6}>
                    <Form.Group className={styles.formGroup}>
                      <Form.Label>Subject</Form.Label>
                      <Form.Select>
                        <option value="1">One</option>
                        <option value="1">Two</option>
                        <option value="1">Three</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col sm={12} md={12}>
                    <Form.Group className={styles.formGroup}>
                      <Form.Label>Message</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={10}
                        placeholder="write your message"
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <div className="d-flex justify-content-center">
                  <button className="button btn-1" type="submit">
                    Submit
                  </button>
                </div>
              </Form>
            </motion.div>
          </Col>
        </Row>
        <Row className={`${styles.section} `}></Row>
      </Container>
    </div>
  );
}

export default AboutPage;
