import React from "react";
import { motion } from "framer-motion";
import styles from "./FoodCard.module.css";
import star from "../../../Assets/Image/star.svg";
function FoodCard(props) {
  return (
    <motion.div
      initial={{ scale: 0.2, opacity: 0 }}
      whileInView={{ scale: 1, opacity: 1 }}
      transition={{ duration: 0.4 }}
      className={styles.card}
    >
      <img src={require("../../../Assets/Image/espresso.jpg")} alt="Espresso" />

      <div className={`${styles.rating} d-flex`}>
        <img src={star} alt="rating" />
        <img src={star} alt="rating" />
        <img src={star} alt="rating" />
        <img src={star} alt="rating" />
      </div>
      <div className="d-flex justify-content-between py-2">
        <p className={styles.name}>Iced Latte Vertical</p>
        <span className={styles.price}>$ 50</span>
      </div>
      <p className={styles.ingrident}>
        Espresso, Milk, Ice and Choice of Flavor(s) - 20oz
      </p>
      <p className={`${styles.offer}`}>
        <span className="important">10%</span> off for new customer.
      </p>
      <button className={`${styles.button} btn btn-1`}>Add to Cart</button>
    </motion.div>
  );
}

export default FoodCard;
