import React from "react";
import styles from "./Modal.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
function Modal(props) {
  if (!props.isOpen) {
    return null;
  } else {
    return (
      <div className={styles.modalOverlay}>
        <div className={styles.modal}>
          <button
            className={styles.btn}
            onClick={props.onClose}
            style={props.header ? { color: "#ffffff" } : { color: "red" }}
          >
            <FontAwesomeIcon icon={faXmark} />
          </button>
          {props.header && (
            <div className={styles.header}>
              {props.success && (
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 123 123"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_360_255)">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M61.44 0C95.373 0 122.881 27.507 122.881 61.439C122.881 95.372 95.373 122.879 61.44 122.879C27.508 122.88 0 95.372 0 61.439C0 27.507 27.508 0 61.44 0ZM34.106 67.678L34.091 67.664C33.306 66.946 32.884 65.979 32.835 64.995C32.786 64.013 33.11 63.01 33.819 62.218C33.829 62.207 33.838 62.197 33.848 62.187C34.565 61.403 35.532 60.98 36.516 60.931C37.505 60.882 38.514 61.211 39.308 61.929L52.264 73.677L83.353 41.118C84.093 40.342 85.076 39.938 86.072 39.914C87.064 39.889 88.066 40.243 88.843 40.981C89.62 41.72 90.023 42.706 90.048 43.7C90.073 44.693 89.718 45.697 88.98 46.473L55.279 81.769C55.256 81.793 55.231 81.816 55.206 81.836C54.491 82.551 53.557 82.931 52.608 82.966C51.634 83.003 50.645 82.673 49.864 81.966L34.118 67.688L34.106 67.678Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_360_255">
                      <rect width="122.881" height="122.88" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              )}
              <h3 className="text-center">{props.header}</h3>
            </div>
          )}
          <div className={styles.body}>{props.children}</div>
          {props.footer && <div className={styles.footer}>{props.footer}</div>}
        </div>
      </div>
    );
  }
}

export default Modal;
