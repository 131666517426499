import React from "react";
import { motion } from "framer-motion";
import Subscribe from "../../Components/Subscribe/Subscribe";
import styles from "./FoodPage.module.css";
import { Col, Container, Form, Row } from "react-bootstrap";
import FoodCard from "../../Components/UiElement/FoodCard/FoodCard";
function DrinkPage(props) {
  return (
    <div className={styles.food}>
      <motion.div
        className={`${styles.bannerTop}`}
        initial={{ x: 200 }}
        whileInView={{ x: 0 }}
        transition={{ duration: 0.4, type: "spring", stiffness: 60 }}
      >
        <Container>
          <Row>
            <Col sm={12} md={6}>
              <motion.div
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 0.4, delay: 0.4 }}
              >
                <p className={styles.title}>Art of Coffee</p>
                <p className={styles.desc}>
                  All the taste, without the caffeine. We decaffeinate green
                  coffee as soon as it lands so you can enjoy maximum freshness.
                  Choose in a range of delicious options.
                </p>
              </motion.div>
            </Col>
            <Col
              sm={12}
              md={6}
              className={`${styles.bannerImg} d-block d-sm-none`}
            >
              <motion.div
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 0.6, delay: 0.4 }}
              >
                <img
                  className="img-fluid"
                  src={require("../../Assets/Image/grinded_coffee.jpg")}
                  alt="Grinding coffee"
                />
              </motion.div>
            </Col>
          </Row>
        </Container>
      </motion.div>

      <Container>
        <Row className={styles.sortSec}>
          <Col sm={10}>
            <p className={styles.title}>Brows Coffee & Drinks</p>
          </Col>
          <Col sm={2}>
            <Form.Group>
              <Form.Label>Sort by</Form.Label>
              <Form.Select
                title="Sort Items By Category"
                className={styles.formSelect}
              >
                <option value={1}>Option One</option>
                <option value={2}>Option Two</option>
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
        <Row className="py-4">
          <Col sm={6} md={3}>
            <div className="d-flex justify-content-between">
              <p>2 filters </p>
              <p>Clear all</p>
            </div>
          </Col>
          <Col sm={6} md={9} className="text-end">
            22 items
          </Col>
        </Row>
        <Row>
          <Col md={3} className={`${styles.filterSec} d-none d-sm-block`}>
            <div className={styles.filterBlock}>
              <p className={styles.title}>Coffee Type</p>
              <div className="d-flex">
                <Form.Check className="pe-3" />{" "}
                <p>
                  Regular (<span>0</span>){" "}
                </p>
              </div>
              <div className="d-flex">
                <Form.Check className="pe-3" />{" "}
                <p>
                  Dark (<span>0</span>){" "}
                </p>
              </div>
            </div>
            <div className={styles.filterBlock}>
              <p className={styles.title}>Coffee Type</p>
              <div className="d-flex">
                <Form.Check className="pe-3" />{" "}
                <p>
                  Regular (<span>0</span>){" "}
                </p>
              </div>
              <div className="d-flex">
                <Form.Check className="pe-3" />{" "}
                <p>
                  Dark (<span>0</span>){" "}
                </p>
              </div>
              <div className="d-flex">
                <Form.Check className="pe-3" />{" "}
                <p>
                  Regular (<span>0</span>){" "}
                </p>
              </div>
              <div className="d-flex">
                <Form.Check className="pe-3" />{" "}
                <p>
                  Dark (<span>0</span>){" "}
                </p>
              </div>
            </div>
            <div className={styles.filterBlock}>
              <p className={styles.title}>Coffee Type</p>
              <div className="d-flex">
                <Form.Check className="pe-3" />{" "}
                <p>
                  Regular (<span>0</span>){" "}
                </p>
              </div>
              <div className="d-flex">
                <Form.Check className="pe-3" />{" "}
                <p>
                  Dark (<span>0</span>){" "}
                </p>
              </div>
              <div className="d-flex">
                <Form.Check className="pe-3" />{" "}
                <p>
                  Regular (<span>0</span>){" "}
                </p>
              </div>
              <div className="d-flex">
                <Form.Check className="pe-3" />{" "}
                <p>
                  Dark (<span>0</span>){" "}
                </p>
              </div>
            </div>
            <div className={styles.filterBlock}>
              <p className={styles.title}>Coffee Type</p>
              <div className="d-flex">
                <Form.Check className="pe-3" />{" "}
                <p>
                  Regular (<span>0</span>){" "}
                </p>
              </div>
              <div className="d-flex">
                <Form.Check className="pe-3" />{" "}
                <p>
                  Dark (<span>0</span>){" "}
                </p>
              </div>
              <div className="d-flex">
                <Form.Check className="pe-3" />{" "}
                <p>
                  Regular (<span>0</span>){" "}
                </p>
              </div>
              <div className="d-flex">
                <Form.Check className="pe-3" />{" "}
                <p>
                  Dark (<span>0</span>){" "}
                </p>
              </div>
            </div>
          </Col>
          <Col md={9}>
            <div className="d-flex flex-wrap justify-content-around">
              <FoodCard />
              <FoodCard />
              <FoodCard />
              <FoodCard />
              <FoodCard />
              <FoodCard />
              <FoodCard />
              <FoodCard />
              <FoodCard />
            </div>
          </Col>
        </Row>
        <Row>
          <Subscribe />
        </Row>
      </Container>
    </div>
  );
}

export default DrinkPage;
