import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { motion } from "framer-motion";
import styles from "./Banner.module.css";
import Coffee from "../../Assets/Image/bannerImage.png";
function Banner(props) {
  return (
    <div className={styles.Banner}>
      <Container>
        <Row>
          <Col lg={6} md={6} sm={12} className={styles.textSection}>
            <motion.div
              initial={{ scale: 0.5 }}
              whileInView={{ scale: 1 }}
              transition={{ duration: 0.6, type: "spring" }}
            >
              <p className={styles.titleTop}>A Best Café House</p>
              <p className={styles.title}>Start Your Day With Morning Coffee</p>
              <Row className={styles.subTitle}>
                <span>Colombian Andean coffee</span>
                <span
                  className={`${styles.separateDot} d-none d-md-block`}
                ></span>
                <span>Ethiopian acidic coffee</span>
              </Row>
              <p className={styles.desc}>
                We love the idea of indulging in your health, and every item on
                our menu is full of lush, organic ingredients, bright flavor and
                beautiful color.
              </p>
              <div>
                <motion.button
                  whileHover={{ scale: 1.09 }}
                  transition={{ duration: 0.1, type: "spring", stiffness: 440 }}
                  className="button btn-1"
                >
                  Get Yours Now
                </motion.button>
                <motion.button
                  whileHover={{ scale: 1.09 }}
                  transition={{ duration: 0.1, type: "spring", stiffness: 440 }}
                  className="button btn-2"
                >
                  Reservation
                </motion.button>
              </div>
            </motion.div>
          </Col>
          <Col lg={6} md={6} sm={12}>
            <motion.div
              initial={{ y: "20vh", opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              transition={{ duration: 1, type: "spring" }}
              className={`${styles.imgContainer}`}
            >
              <img className="img-fluid" src={Coffee} alt="Coffee" />
            </motion.div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Banner;
