import { string, date, object, ref, boolean } from "yup";

export const loginSchema = object({
  email: string()
    .email("Email must be Valid.")
    .matches(/^\S+@\S+\.\S+$/, "Invalid Email Address.")
    .required("Email Address Required."),
  password: string()
    .matches(
      /^(?=.*[A-Z])(?=.*[a-zA-Z])(?=.*\d).{6,}$/,
      "At least one Uppercase and one number with mimimum 6 charecter."
    )
    .max(20, "At most 20 charecter.")
    .required("Password is Required"),
});

export const registerSchema = object({
  name: string().min(3).required(),
  email: string()
    .email("Invalid email Address")
    .matches(/^\S+@\S+\.\S+$/, "Invalid Email Address.")
    .required("Email Address Required."),
  password: string()
    .matches(
      /^(?=.*[A-Z])(?=.*[a-zA-Z])(?=.*\d).{6,}$/,
      "At least one Uppercase and one number with mimimum 6 charecter."
    )
    .max(20, "At most 20 charecter.")
    .required("Password is Required"),
  confirmPassword: string()
    .oneOf([ref("password"), null], "Password Do not matched.")
    .required("Confirm passord is required."),
  terms: boolean()
    .oneOf([true], "You must accept the terms and conditions")
    .required("You must accept the terms and conditions"),
});

export const tableSchema = object({
  person: string().required(),
  date: date().required(),
  time: string().required(),
});
export const reservationSchema = object({
  name: string().min(3, "At least 3 charecter.").required("Name is Required."),
  email: string()
    .email("Invalid email Address")
    .matches(/^\S+@\S+\.\S+$/, "Invalid Email Address.")
    .required("Email Address Required."),
  phone: string()
    .matches(/^01\d{9}$/, "Invalid Phone Number.")
    .required("Phone Number needed."),
  note: string().max(250, "Write in Max 250 Charecter"),
  reminder: boolean(),
  offer: boolean(),
});
