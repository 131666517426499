import React from "react";
import { Container } from "react-bootstrap";
import { motion } from "framer-motion";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper";
import slide1 from "../../Assets/Image/slide1.png";
import quote from "../../Assets/Image/quote.svg";
import styles from "./Quotes.module.css";
import "swiper/css";
import "swiper/css/pagination";
function Quotes(props) {
  return (
    <motion.div
      initial={{ x: "-20vw" }}
      whileInView={{ x: 0 }}
      transition={{ duration: 0.6 }}
      className={styles.quotes}
    >
      <Container>
        <motion.div
          initial={{ x: "-20vw", scale: 0.5 }}
          whileInView={{ x: 0, scale: 1 }}
          transition={{ duration: 0.6 }}
        >
          <Swiper
            pagination={true}
            modules={[Pagination, Autoplay]}
            autoplay={true}
            className="mySwiper"
          >
            <SwiperSlide>
              <div className={styles.slide}>
                <div className={styles.slideText}>
                  <img
                    className={`${styles.quote}  mt-0`}
                    src={quote}
                    alt="slide 1"
                  />
                  <p style={{ margin: "20px 5px" }}>
                    Coffee is a way of stealing time which should by rights
                    belong to your older self.
                  </p>
                  <img
                    className={`${styles.quote} ${styles.quoteEnd}  mt-auto mb-0`}
                    src={quote}
                    alt="slide 1"
                  />
                </div>
                <div className={styles.icon}>
                  <img className="img-fluid mt-4" src={slide1} alt="slide 1" />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={styles.slide}>
                <div className={styles.slideText}>
                  <img
                    className={`${styles.quote}  mt-0`}
                    src={quote}
                    alt="slide 1"
                  />
                  <p style={{ margin: "20px 5px" }}>
                    Coffee is a way of stealing time which should by rights
                    belong to your older self.
                  </p>
                  <img
                    className={`${styles.quote} ${styles.quoteEnd}  mt-auto mb-0`}
                    src={quote}
                    alt="slide 1"
                  />
                </div>
                <div className={styles.icon}>
                  <img className="img-fluid mt-4" src={slide1} alt="slide 1" />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={styles.slide}>
                <div className={styles.slideText}>
                  <img
                    className={`${styles.quote}  mt-0`}
                    src={quote}
                    alt="slide 1"
                  />
                  <p style={{ margin: "20px 5px" }}>
                    Coffee is a way of stealing time which should by rights
                    belong to your older self.
                  </p>
                  <img
                    className={`${styles.quote} ${styles.quoteEnd}  mt-auto mb-0`}
                    src={quote}
                    alt="slide 1"
                  />
                </div>
                <div className={styles.icon}>
                  <img className="img-fluid mt-4" src={slide1} alt="slide 1" />
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </motion.div>
      </Container>
    </motion.div>
  );
}

export default Quotes;
