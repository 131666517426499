import React from "react";
import { motion } from "framer-motion";
import styles from "./Card2.module.css";
function Card2(props) {
  if (props.routine) {
    return (
      <>
        <div className={`${styles.card} ${styles.routine}`}>
          <img
            className={`${styles.img}`}
            src={props.img}
            style={props.style}
            alt="Routine"
          />
          <div className={styles.overlay}></div>
          <div className={styles.info}>
            <h3 className={styles.title}>Opening Hours</h3>
            <table className=" text-white">
              <tbody>
                <tr>
                  <td className={styles.day}>Monday</td>
                  <td>
                    <hr></hr>
                  </td>
                  <td className={styles.time}>7:00 AM - 5:00 PM</td>
                </tr>
                <tr>
                  <td className={styles.day}>Tuesday</td>
                  <td>
                    <hr></hr>
                  </td>
                  <td className={styles.time}>7:00 AM - 5:00 PM</td>
                </tr>
                <tr>
                  <td className={styles.day}>Wednesday</td>
                  <td>
                    <hr></hr>
                  </td>
                  <td className={styles.time}>7:00 AM - 5:00 PM</td>
                </tr>
                <tr>
                  <td className={styles.day}>Thursday</td>
                  <td>
                    <hr></hr>
                  </td>
                  <td className={styles.time}>7:00 AM - 5:00 PM</td>
                </tr>
                <tr>
                  <td className={styles.day}>Friday</td>
                  <td>
                    <hr></hr>
                  </td>
                  <td className={styles.time}>7:00 AM - 5:00 PM</td>
                </tr>
                <tr>
                  <td className={styles.day}>Saturday</td>
                  <td>
                    <hr></hr>
                  </td>
                  <td className={styles.time}>7:00 AM - 5:00 PM</td>
                </tr>
                <tr>
                  <td className={styles.day}>Sunday</td>
                  <td>
                    <hr></hr>
                  </td>
                  <td className={styles.closed}>CLOSED</td>
                </tr>
              </tbody>
            </table>
            <a className={styles.call} href="tel:+15857868181">
              Call: + 1 585 786 8181
            </a>
          </div>
          <motion.button
            whileHover={{ scale: 0.9 }}
            transition={{ duration: 0.1, type: "spring", stiffness: 240 }}
            className={`${styles.btn} button btn-1`}
          >
            Book your table
          </motion.button>
        </div>
      </>
    );
  } else {
    return (
      <div className={styles.card}>
        <img
          className={`${styles.img}`}
          src={props.img}
          style={props.style}
          alt="Opening"
        />
        <h5>{props.title}</h5>
        <p>{props.desc}</p>
        <footer>{props.footer && <img src={props.footer} alt="Wifi" />}</footer>
      </div>
    );
  }
}

export default Card2;
