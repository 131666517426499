import React, { useState } from "react";
import styles from "./PwReset.module.css";
import { Form, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
function PwReset(props) {
  const [resetState, setResetState] = useState("email");
  const [otp, setOtp] = useState(Array(4).fill(" "));
  return (
    <Container className={styles.login}>
      <Row>
        <Col md={6} sm={12} className={`${styles.img} d-none d-md-block`}>
          <img
            className="img-fluid"
            src={require("../../Assets/Image/recover.jpg")}
            alt="Login"
          />
        </Col>
        {resetState === "email" && (
          <Col md={6} sm={12} className={styles.loginSec}>
            <p className={styles.title}>Forgot Password!</p>
            <p>Don’t Worry, We Will Send You a Reset link.</p>
            <Form className={styles.form}>
              <Row>
                <Col sm={12}>
                  <Form.Group className={styles.formGroup}>
                    <Form.Label>E-Mail Address</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Enter your e-mail Address"
                    />
                  </Form.Group>
                </Col>

                <p className="warning">Invalid Email or Password</p>
              </Row>
              <div className="d-flex justify-content-start flex-wrap flex-md-nowrap gap-4 mt-4">
                <button
                  onClick={() => setResetState("otp")}
                  className="button btn-1 m-0 py-2 px-4"
                >
                  ENTER
                </button>
              </div>
            </Form>
            <p className={`${styles.sideText} mt-4`}>
              Already Have an Account?{" "}
              <Link to="/login" className="important">
                Sign In
              </Link>
            </p>
          </Col>
        )}
        {resetState === "otp" && (
          <Col md={6} sm={12} className={styles.loginSec}>
            <p className={styles.title}>Forgot Password!</p>
            <p>
              Please insert 4 digit <span className="important">OTP</span>
            </p>
            <Form className={styles.form}>
              <Row>
                <Col sm={12}>
                  <Form.Group className={styles.formGroup}>
                    <div className="d-flex gap-4">
                      {otp.map((data, index) => {
                        return (
                          <Form.Control
                            type="number"
                            maxLength={1}
                            // value={data}
                            key={index}
                          />
                        );
                      })}
                    </div>
                  </Form.Group>
                </Col>

                <p className="warning">OTP didn't Match.</p>
              </Row>
              <div className="d-flex justify-content-start flex-wrap flex-md-nowrap gap-4 mt-4">
                <button
                  onClick={() => setResetState("otp")}
                  className="button btn-1 m-0 py-2 px-4"
                >
                  ENTER
                </button>
              </div>
            </Form>
            <p className={`${styles.sideText} mt-4`}>
              Already Have an Account?{" "}
              <Link to="/login" className="important">
                SIGN IN
              </Link>
            </p>
          </Col>
        )}
      </Row>
    </Container>
  );
}

export default PwReset;
