import React, { useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { motion } from "framer-motion";
import { useFormik } from "formik";
import { formatter } from "../../Components/Util/methods";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TABLE_DATA } from "../../Components/App/data";
import {
  faCalendarDay,
  faChair,
  faClockRotateLeft,
  faPeopleGroup,
} from "@fortawesome/free-solid-svg-icons";
import logo from "../../Assets/Image/Logo.svg";
import tableIcon from "../../Assets/Image/table.svg";
import styles from "./Reservation.module.css";
import {
  reservationSchema,
  tableSchema,
} from "../../Components/Util/validation";
import Modal from "../../Components/Modal/Modal";
import { useNavigate } from "react-router-dom";

function Reservation(props) {
  const [state, setState] = useState("table");
  const [tableData, setTableData] = useState([]);
  const [SelectedTable, setSelectedTable] = useState({});
  const [reserveInfo, setReserveInfo] = useState({});
  const [reserveData, setReserveData] = useState({});
  const [modal, setModal] = useState(false);
  const navigate = useNavigate();
  const formikTable = useFormik({
    initialValues: {
      person: "",
      date: "",
      time: "",
    },
    validationSchema: tableSchema,
    onSubmit: (values) => {
      const person = values.person;
      const timeDate = formatter(
        formikTable.values.date,
        formikTable.values.time
      );
      setReserveInfo({
        person,
        time: timeDate.timeFormatted,
        date: timeDate.dateFormatted,
      });
      setTableData(TABLE_DATA);
    },
  });
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      note: "",
      reminder: false,
      offer: false,
    },
    validationSchema: reservationSchema,
    onSubmit: (values) => {
      const data = {
        ...values,
        ...reserveInfo,
      };

      setReserveData(data);
      sessionStorage.setItem("reservation", JSON.stringify(data));
      setModal(true);
      formikTable.resetForm();
      formik.resetForm();
      setTableData([]);
    },
  });
  const tableSelection = (e) => {
    const table = JSON.parse(e.target.value);
    setSelectedTable(table);
  };
  const tableConfirm = () => {
    const info = {
      ...reserveInfo,
      table: SelectedTable.type,
      time: SelectedTable.time,
    };

    setReserveInfo(info);
    setState("details");
  };
  return (
    <div className={styles.reservation}>
      <motion.div
        className={`${styles.bannerTop}`}
        initial={{ x: 200 }}
        whileInView={{ x: 0 }}
        transition={{ duration: 0.4, type: "spring", stiffness: 60 }}
      >
        <Container>
          <Row>
            <Col md={6} sm={12}>
              <p className={styles.title}>Reservation</p>
              <p className={styles.desc}>
                Reservations are available for dinner, starting at 3pm each day.
                For reservations of 9+ guests, please reach out to
                info@mrcafe.com
              </p>

              <p>Make A Reservation</p>
              <div className={styles.radio}>
                <Form.Check
                  type="radio"
                  name="state"
                  value="table"
                  label="Find A Table"
                  onChange={(e) => setState(e.target.value)}
                  checked={state === "table"}
                />
                <Form.Check
                  type="radio"
                  name="state"
                  value="details"
                  label="Your Details"
                  onChange={(e) => setState(e.target.value)}
                  checked={state === "details"}
                  disabled
                />
              </div>
              <div className={styles.form}>
                <motion.div
                  initial={{ x: 0, opacity: 1 }}
                  exit={{ x: -200, opacity: 0, transition: { duration: 0.5 } }}
                  style={state === "table" ? null : { display: "none" }}
                >
                  <Form
                    onSubmit={formikTable.handleSubmit}
                    className={styles.timeSelection}
                  >
                    <Form.Select
                      name="person"
                      id="person"
                      onBlur={formikTable.handleBlur}
                      onChange={formikTable.handleChange}
                      value={formikTable.values.person}
                      className={styles.selection}
                    >
                      <option value={""} disabled>
                        Select People
                      </option>
                      <option value={"2"}>2 People</option>
                      <option value={"3"}>3 People</option>
                      <option value={"4"}>4 People</option>
                      <option value={"5"}>5 People</option>
                    </Form.Select>

                    <Form.Control
                      name="date"
                      id="date"
                      type="date"
                      onBlur={formikTable.handleBlur}
                      onChange={formikTable.handleChange}
                      value={formikTable.values.date}
                      className={styles.selection}
                    />
                    <Form.Control
                      name="time"
                      id="time"
                      onBlur={formikTable.handleBlur}
                      onChange={formikTable.handleChange}
                      value={formikTable.values.time}
                      className={styles.selection}
                      type="time"
                    />
                    <button className={`btn btn-1 ${styles.btn}`} type="submit">
                      Find Table
                    </button>
                  </Form>
                  <div className="d-flex gap-4">
                    {formikTable.errors.person && formikTable.touched.person ? (
                      <p className="warning">{formikTable.errors.person}</p>
                    ) : null}
                    {formikTable.errors.date && formikTable.touched.date ? (
                      <p className="warning">{formikTable.errors.date}</p>
                    ) : null}
                    {formikTable.errors.time && formikTable.touched.time ? (
                      <p className="warning">{formikTable.errors.time}</p>
                    ) : null}
                  </div>
                  {tableData.length > 0 ? (
                    <div>
                      <div>
                        <p className={styles.subTitle}>
                          Select your Seating Type
                        </p>
                        <p>
                          The following options are available for a reservation.
                        </p>
                      </div>
                      <div className={styles.table}>
                        {TABLE_DATA.map((table, index) => {
                          return (
                            <div
                              key={index}
                              className="d-flex align-items-center justify-content-between my-2"
                            >
                              <p className="m-0">{table.type}</p>
                              <button
                                onClick={tableSelection}
                                style={
                                  SelectedTable.type === table.type
                                    ? { backgroundColor: "#403c34" }
                                    : null
                                }
                                type="button"
                                value={JSON.stringify(table)}
                                className="button btn-1 btn-sm"
                                disabled={table.time === "Unavailable"}
                              >
                                {table.time}
                              </button>
                            </div>
                          );
                        })}
                      </div>
                      <button
                        onClick={tableConfirm}
                        className="btn btn-1 mx-auto mt-4 d-flex "
                        type="button"
                      >
                        Confirm your Table
                      </button>
                    </div>
                  ) : (
                    <div className="w-100 h-100 d-flex justify-content-center my-4 py-4">
                      <img
                        className="p-4 w-25 h-auto text-center"
                        src={tableIcon}
                        alt="Table"
                      />
                    </div>
                  )}
                </motion.div>
                <div style={state === "details" ? null : { display: "none" }}>
                  <Form onSubmit={formik.handleSubmit}>
                    <p className={styles.info}>
                      Due to limited availability, we can hold this table for
                      you for 5:00 minutes
                    </p>
                    <Row>
                      <Col md={6} sm={12}>
                        <Row>
                          <Col sm={12}>
                            <Form.Group className={styles.formGroup}>
                              <Form.Label>Full Name</Form.Label>
                              <Form.Control
                                name="name"
                                id="name"
                                type="text"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.name}
                                placeholder="Enter your Name"
                              />
                            </Form.Group>
                            {formik.errors.name && formik.touched.name ? (
                              <p className="warning">{formik.errors.name}</p>
                            ) : null}
                          </Col>
                          <Col sm={12}>
                            <Form.Group className={styles.formGroup}>
                              <Form.Label>E-Mail Address</Form.Label>
                              <Form.Control
                                name="email"
                                id="email"
                                type="email"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.email}
                                placeholder="Enter your e-mail"
                              />
                            </Form.Group>
                            {formik.errors.email && formik.touched.email ? (
                              <p className="warning">{formik.errors.email}</p>
                            ) : null}
                          </Col>
                          <Col sm={12}>
                            <Form.Group className={styles.formGroup}>
                              <Form.Label>Contact Number</Form.Label>
                              <Form.Control
                                name="phone"
                                id="phone"
                                type="tel"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.phone}
                                placeholder="Enter your Phone Number"
                              />
                            </Form.Group>
                            {formik.errors.phone && formik.touched.phone ? (
                              <p className="warning">{formik.errors.phone}</p>
                            ) : null}
                          </Col>
                          <Col sm={12}>
                            <Form.Group className={styles.formGroup}>
                              <Form.Label>
                                Add a Special Request (Optional)
                              </Form.Label>
                              <Form.Control
                                name="note"
                                id="note"
                                type="text"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.note}
                                placeholder="Special Request"
                              />
                            </Form.Group>
                          </Col>
                          <Col sm={12}>
                            <div className={styles.checkbox}>
                              <Form.Check
                                name="reminder"
                                id="reminder"
                                type="checkbox"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.reminder}
                              />
                              <p>
                                Yes, I want to get text updates and reminders
                                about my reservations.*
                              </p>
                            </div>
                          </Col>
                          <Col sm={12}>
                            <div className={styles.checkbox}>
                              <Form.Check
                                name="offer"
                                id="offer"
                                type="checkbox"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.offer}
                              />
                              <p>
                                Sign me up to receive dining offers and news
                                from this restaurant by email.
                              </p>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <Col md={6} sm={12} className={styles.infoSec}>
                        <img src={logo} alt="logo" />
                        <div className={styles.details}>
                          <div className={styles.detailsLine}>
                            <FontAwesomeIcon
                              className="me-3"
                              icon={faCalendarDay}
                            />
                            <span>{reserveInfo?.date}</span>
                          </div>
                          <div className={styles.detailsLine}>
                            <FontAwesomeIcon
                              className="me-3"
                              icon={faClockRotateLeft}
                            />
                            <span>{reserveInfo?.time}</span>
                          </div>
                          <div className={styles.detailsLine}>
                            <FontAwesomeIcon
                              className="me-3"
                              icon={faPeopleGroup}
                            />
                            <span>{reserveInfo?.person} People</span>
                          </div>
                          <div className={styles.detailsLine}>
                            <FontAwesomeIcon className="me-3" icon={faChair} />
                            <span>{SelectedTable.type}</span>
                          </div>
                        </div>
                        <p className={styles.detailsLine}>
                          What to know before you go...
                        </p>
                        <p className={styles.description}>
                          As per the most recent local government updates, masks
                          are strongly recommended for guests at all New
                          Waterloo properties in Austin. Please know that we
                          respect each guest's decision, and we also encourage
                          those who have yet to be vaccinated to continue do so.
                          We appreciate you extending the same respect to your
                          fellow guests while visiting our properties!
                        </p>
                      </Col>
                    </Row>

                    <div className="d-flex justify-content-start flex-wrap flex-md-nowrap gap-4 mt-4">
                      <button
                        className="button w-100 btn-1 m-0 py-2 px-4"
                        type="submit"
                      >
                        Confirm Reservation
                      </button>
                    </div>
                  </Form>
                </div>
              </div>
            </Col>
            <Col md={6} sm={12}>
              <img
                className="img-fluid"
                src={require("../../Assets/Image/reservation.png")}
                alt="reservation"
              />
            </Col>
          </Row>
        </Container>
      </motion.div>
      <Modal
        success
        isOpen={modal}
        onClose={() => {
          setModal(false);
          navigate("/");
        }}
        header="Reservation Confirmed"
      >
        <img
          style={{ width: "48px", height: "48px", marginBottom: "10px" }}
          src={require("../../Assets/Image/coffee1.png")}
          alt="Coffee"
        />
        <p className="text-center">
          {reserveData?.name}, Your Reservation is Confirmed. A confirmation
          mail sent to your e-mail.
        </p>
        <p className="text-center">Thank you for being with Us.</p>
        <p className={styles.footer}>
          By creating an account you agree to the MR. Cafe terms of Use and
          Privacy Policy.
        </p>
      </Modal>
    </div>
  );
}

export default Reservation;
