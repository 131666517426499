import React from "react";
import styles from "./Review.module.css";
import { Col, Container, Row } from "react-bootstrap";
import { motion } from "framer-motion";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper";
import quote from "../../Assets/Image/quote.svg";
import star from "../../Assets/Image/star.svg";
import "swiper/css";
import "swiper/css/pagination";
function Review(props) {
  return (
    <motion.div
      initial={{ scale: 0 }}
      whileInView={{ scale: 1 }}
      transition={{ duration: 0.6, type: "spring", stiffness: 80 }}
      className={styles.review}
    >
      <Container className={styles.container}>
        <Row>
          <Col sm={12} md={9} lg={9}>
            <h3 className={styles.title}>What Our Customer Say</h3>
            <Swiper
              pagination={true}
              modules={[Pagination, Autoplay]}
              autoplay={true}
              className="mySwiper"
              style={{ width: "auto", height: "auto" }}
            >
              <SwiperSlide>
                <div className={styles.slide}>
                  <div className={styles.slideText}>
                    <img
                      className={`${styles.quote} img-fluid mt-0`}
                      src={quote}
                      alt="slide 1"
                    />
                    <p style={{ margin: "20px 5px" }}>
                      I like the coffee but a little expensive to recommend to
                      friends. Can buy acceptable equivalent in Waitrose for
                      half the price
                    </p>
                    <img
                      className={`${styles.quote} ${styles.quoteEnd} img-fluid mt-auto mb-0`}
                      src={quote}
                      alt="slide 1"
                    />
                  </div>
                  <div className={styles.clientInfo}>
                    <Row className="mb-4">
                      <p className={styles.name}>Esther Howard</p>
                      <div className={styles.rating}>
                        <img src={star} alt="rating" />
                        <img src={star} alt="rating" />
                        <img src={star} alt="rating" />
                        <img src={star} alt="rating" />
                      </div>
                    </Row>

                    <Row>
                      <Col sm={12} md={3} lg={3}>
                        <svg
                          width="22"
                          height="22"
                          viewBox="0 0 22 22"
                          fill="#403c34"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clipPath="url(#clip0_138_255)">
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M15.6702 7.06023L14.5902 5.72023C14.4202 5.50023 14.3102 5.24023 14.2802 4.95023L14.0902 3.25023C14.0517 2.91078 13.8992 2.59443 13.6576 2.35286C13.416 2.11129 13.0997 1.95878 12.7602 1.92023L11.0602 1.73023C10.7602 1.70023 10.5002 1.57023 10.2802 1.40023L8.94023 0.320234C8.39023 -0.119766 7.61023 -0.119766 7.06023 0.320234L5.72023 1.40023C5.50023 1.57023 5.24023 1.68023 4.95023 1.71023L3.25023 1.90023C2.55023 1.98023 2.00023 2.53023 1.92023 3.23023L1.73023 4.93023C1.70023 5.23023 1.57023 5.49023 1.40023 5.71023L0.320234 7.05023C-0.119766 7.60023 -0.119766 8.38023 0.320234 8.93023L1.40023 10.2702C1.57023 10.4902 1.68023 10.7502 1.71023 11.0402L1.90023 12.7402C1.98023 13.4402 2.53023 13.9902 3.23023 14.0702L4.93023 14.2602C5.23023 14.2902 5.49023 14.4202 5.71023 14.5902L7.05023 15.6702C7.60023 16.1102 8.38023 16.1102 8.93023 15.6702L10.2702 14.5902C10.4902 14.4202 10.7502 14.3102 11.0402 14.2802L12.7402 14.0902C13.4402 14.0102 13.9902 13.4602 14.0702 12.7602L14.2602 11.0602C14.2902 10.7602 14.4202 10.5002 14.5902 10.2802L15.6702 8.94023C16.1102 8.39023 16.1102 7.61023 15.6702 7.06023ZM6.50023 12.0002L3.00023 8.50023L4.50023 7.00023L6.50023 9.00023L11.5002 4.00023L13.0002 5.55023L6.50023 12.0002Z"
                              fill="black"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_138_255">
                              <rect width="16" height="16" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                        <span className={styles.verified}>
                          Verified Customer
                        </span>
                      </Col>
                      <Col sm={12} md={3} lg={3} className={styles.social}>
                        <a
                          className={styles.socialLink}
                          href="https://facebook.com"
                        >
                          <svg
                            width="25"
                            height="25"
                            viewBox="0 0 25 25"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M12.5 1.5C6.425 1.5 1.5 6.425 1.5 12.5C1.5 18.015 5.5625 22.5685 10.856 23.364V15.4155H8.1345V12.524H10.856V10.6C10.856 7.4145 12.408 6.016 15.0555 6.016C16.3235 6.016 16.994 6.11 17.3115 6.153V8.677H15.5055C14.3815 8.677 13.989 9.7425 13.989 10.9435V12.524H17.283L16.836 15.4155H13.989V23.3875C19.358 22.659 23.5 18.0685 23.5 12.5C23.5 6.425 18.575 1.5 12.5 1.5Z"
                              fill="#403C34"
                            />
                          </svg>
                        </a>
                        <a
                          className={styles.socialLink}
                          href="https://facebook.com"
                        >
                          <svg
                            width="25"
                            height="25"
                            viewBox="0 0 25 25"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clipPath="url(#clip0_139_259)">
                              <path
                                d="M25.0312 5.21875C24.1074 5.62891 23.1172 5.9043 22.0762 6.0293C23.1387 5.39258 23.9551 4.38477 24.3379 3.18555C23.3457 3.77344 22.2422 4.20117 21.0723 4.43164C20.1348 3.43164 18.7988 2.80859 17.3203 2.80859C14.4805 2.80859 12.1777 5.10937 12.1777 7.94922C12.1777 8.35156 12.2246 8.74414 12.3125 9.12109C8.03906 8.90625 4.25195 6.85937 1.71484 3.74805C1.27148 4.50977 1.01953 5.39258 1.01953 6.33398C1.01953 8.11719 1.92578 9.69141 3.30664 10.6152C2.46289 10.5879 1.66992 10.3555 0.976562 9.9707C0.976562 9.99219 0.976562 10.0137 0.976562 10.0352C0.976562 12.5273 2.75 14.6035 5.09961 15.0781C4.66992 15.1953 4.21484 15.2578 3.74609 15.2578C3.41406 15.2578 3.0918 15.2266 2.77734 15.1641C3.43359 17.2051 5.33203 18.6953 7.58008 18.7363C5.82227 20.1152 3.60547 20.9355 1.19531 20.9355C0.779297 20.9355 0.371094 20.9121 -0.0292969 20.8633C2.24414 22.3242 4.94727 23.1738 7.85156 23.1738C17.3086 23.1738 22.4805 15.3398 22.4805 8.54688C22.4805 8.32422 22.4746 8.09961 22.4668 7.88086C23.4707 7.15625 24.3418 6.25 25.0312 5.21875Z"
                                fill="#403C34"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_139_259">
                                <rect width="25" height="25" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </a>
                        <a
                          className={styles.socialLink}
                          href="https://facebook.com"
                        >
                          <svg
                            width="25"
                            height="25"
                            viewBox="0 0 25 25"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M25 9.90499V20.982C25 21.5958 24.7814 22.1213 24.3443 22.5585C23.9072 22.9956 23.3817 23.2141 22.7679 23.2141H2.23214C1.6183 23.2141 1.09282 22.9956 0.655692 22.5585C0.218564 22.1213 0 21.5958 0 20.982V9.90499C0.409226 10.3607 0.878906 10.7653 1.40904 11.1187C4.77586 13.4067 7.08705 15.011 8.34263 15.9318C8.87277 16.3224 9.30292 16.627 9.63309 16.8456C9.96326 17.0641 10.4027 17.2873 10.9515 17.5152C11.5002 17.7431 12.0117 17.857 12.486 17.857H12.514C12.9883 17.857 13.4998 17.7431 14.0485 17.5152C14.5973 17.2873 15.0367 17.0641 15.3669 16.8456C15.6971 16.627 16.1272 16.3224 16.6574 15.9318C18.2385 14.7878 20.5543 13.1835 23.6049 11.1187C24.135 10.756 24.6001 10.3514 25 9.90499ZM25 5.80343C25 6.53818 24.7721 7.24037 24.3164 7.91002C23.8607 8.57966 23.2933 9.15165 22.6144 9.62598C19.1174 12.0534 16.941 13.5648 16.0854 14.16C15.9924 14.2251 15.7947 14.367 15.4925 14.5855C15.1902 14.8041 14.9391 14.9808 14.7391 15.1157C14.5392 15.2505 14.2973 15.4016 14.0137 15.5691C13.73 15.7365 13.4626 15.862 13.2115 15.9457C12.9604 16.0294 12.7279 16.0713 12.514 16.0713H12.486C12.2721 16.0713 12.0396 16.0294 11.7885 15.9457C11.5374 15.862 11.27 15.7365 10.9863 15.5691C10.7027 15.4016 10.4608 15.2505 10.2609 15.1157C10.0609 14.9808 9.8098 14.8041 9.50753 14.5855C9.20526 14.367 9.00763 14.2251 8.91462 14.16C8.06827 13.5648 6.84989 12.7161 5.25949 11.614C3.66908 10.5119 2.71577 9.84919 2.39955 9.62598C1.82292 9.23535 1.27883 8.69824 0.767299 8.01465C0.255766 7.33105 0 6.69629 0 6.11035C0 5.38491 0.192987 4.78037 0.578962 4.29674C0.964937 3.8131 1.516 3.57129 2.23214 3.57129H22.7679C23.3724 3.57129 23.8956 3.78985 24.3373 4.22698C24.7791 4.66411 25 5.18959 25 5.80343Z"
                              fill="#403C34"
                            />
                          </svg>
                        </a>
                      </Col>
                    </Row>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className={styles.slide}>
                  <div className={styles.slideText}>
                    <img
                      className={`${styles.quote} img-fluid mt-0`}
                      src={quote}
                      alt="slide 1"
                    />
                    <p style={{ margin: "20px 5px" }}>
                      I like the coffee but a little expensive to recommend to
                      friends. Can buy acceptable equivalent in Waitrose for
                      half the price
                    </p>
                    <img
                      className={`${styles.quote} ${styles.quoteEnd} img-fluid mt-auto mb-0`}
                      src={quote}
                      alt="slide 1"
                    />
                  </div>
                  <div className={styles.clientInfo}>
                    <Row className="mb-4">
                      <p className={styles.name}>Esther Howard</p>
                      <div className={styles.rating}>
                        <img src={star} alt="rating" />
                        <img src={star} alt="rating" />
                        <img src={star} alt="rating" />
                        <img src={star} alt="rating" />
                      </div>
                    </Row>

                    <Row>
                      <Col sm={12} md={3} lg={3}>
                        <svg
                          width="22"
                          height="22"
                          viewBox="0 0 22 22"
                          fill="#403c34"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clipPath="url(#clip0_138_255)">
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M15.6702 7.06023L14.5902 5.72023C14.4202 5.50023 14.3102 5.24023 14.2802 4.95023L14.0902 3.25023C14.0517 2.91078 13.8992 2.59443 13.6576 2.35286C13.416 2.11129 13.0997 1.95878 12.7602 1.92023L11.0602 1.73023C10.7602 1.70023 10.5002 1.57023 10.2802 1.40023L8.94023 0.320234C8.39023 -0.119766 7.61023 -0.119766 7.06023 0.320234L5.72023 1.40023C5.50023 1.57023 5.24023 1.68023 4.95023 1.71023L3.25023 1.90023C2.55023 1.98023 2.00023 2.53023 1.92023 3.23023L1.73023 4.93023C1.70023 5.23023 1.57023 5.49023 1.40023 5.71023L0.320234 7.05023C-0.119766 7.60023 -0.119766 8.38023 0.320234 8.93023L1.40023 10.2702C1.57023 10.4902 1.68023 10.7502 1.71023 11.0402L1.90023 12.7402C1.98023 13.4402 2.53023 13.9902 3.23023 14.0702L4.93023 14.2602C5.23023 14.2902 5.49023 14.4202 5.71023 14.5902L7.05023 15.6702C7.60023 16.1102 8.38023 16.1102 8.93023 15.6702L10.2702 14.5902C10.4902 14.4202 10.7502 14.3102 11.0402 14.2802L12.7402 14.0902C13.4402 14.0102 13.9902 13.4602 14.0702 12.7602L14.2602 11.0602C14.2902 10.7602 14.4202 10.5002 14.5902 10.2802L15.6702 8.94023C16.1102 8.39023 16.1102 7.61023 15.6702 7.06023ZM6.50023 12.0002L3.00023 8.50023L4.50023 7.00023L6.50023 9.00023L11.5002 4.00023L13.0002 5.55023L6.50023 12.0002Z"
                              fill="black"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_138_255">
                              <rect width="16" height="16" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                        <span className={styles.verified}>
                          Verified Customer
                        </span>
                      </Col>
                      <Col sm={12} md={3} lg={3} className={styles.social}>
                        <a
                          className={styles.socialLink}
                          href="https://facebook.com"
                        >
                          <svg
                            width="25"
                            height="25"
                            viewBox="0 0 25 25"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M12.5 1.5C6.425 1.5 1.5 6.425 1.5 12.5C1.5 18.015 5.5625 22.5685 10.856 23.364V15.4155H8.1345V12.524H10.856V10.6C10.856 7.4145 12.408 6.016 15.0555 6.016C16.3235 6.016 16.994 6.11 17.3115 6.153V8.677H15.5055C14.3815 8.677 13.989 9.7425 13.989 10.9435V12.524H17.283L16.836 15.4155H13.989V23.3875C19.358 22.659 23.5 18.0685 23.5 12.5C23.5 6.425 18.575 1.5 12.5 1.5Z"
                              fill="#403C34"
                            />
                          </svg>
                        </a>
                        <a
                          className={styles.socialLink}
                          href="https://facebook.com"
                        >
                          <svg
                            width="25"
                            height="25"
                            viewBox="0 0 25 25"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clipPath="url(#clip0_139_259)">
                              <path
                                d="M25.0312 5.21875C24.1074 5.62891 23.1172 5.9043 22.0762 6.0293C23.1387 5.39258 23.9551 4.38477 24.3379 3.18555C23.3457 3.77344 22.2422 4.20117 21.0723 4.43164C20.1348 3.43164 18.7988 2.80859 17.3203 2.80859C14.4805 2.80859 12.1777 5.10937 12.1777 7.94922C12.1777 8.35156 12.2246 8.74414 12.3125 9.12109C8.03906 8.90625 4.25195 6.85937 1.71484 3.74805C1.27148 4.50977 1.01953 5.39258 1.01953 6.33398C1.01953 8.11719 1.92578 9.69141 3.30664 10.6152C2.46289 10.5879 1.66992 10.3555 0.976562 9.9707C0.976562 9.99219 0.976562 10.0137 0.976562 10.0352C0.976562 12.5273 2.75 14.6035 5.09961 15.0781C4.66992 15.1953 4.21484 15.2578 3.74609 15.2578C3.41406 15.2578 3.0918 15.2266 2.77734 15.1641C3.43359 17.2051 5.33203 18.6953 7.58008 18.7363C5.82227 20.1152 3.60547 20.9355 1.19531 20.9355C0.779297 20.9355 0.371094 20.9121 -0.0292969 20.8633C2.24414 22.3242 4.94727 23.1738 7.85156 23.1738C17.3086 23.1738 22.4805 15.3398 22.4805 8.54688C22.4805 8.32422 22.4746 8.09961 22.4668 7.88086C23.4707 7.15625 24.3418 6.25 25.0312 5.21875Z"
                                fill="#403C34"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_139_259">
                                <rect width="25" height="25" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </a>
                        <a
                          className={styles.socialLink}
                          href="https://facebook.com"
                        >
                          <svg
                            width="25"
                            height="25"
                            viewBox="0 0 25 25"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M25 9.90499V20.982C25 21.5958 24.7814 22.1213 24.3443 22.5585C23.9072 22.9956 23.3817 23.2141 22.7679 23.2141H2.23214C1.6183 23.2141 1.09282 22.9956 0.655692 22.5585C0.218564 22.1213 0 21.5958 0 20.982V9.90499C0.409226 10.3607 0.878906 10.7653 1.40904 11.1187C4.77586 13.4067 7.08705 15.011 8.34263 15.9318C8.87277 16.3224 9.30292 16.627 9.63309 16.8456C9.96326 17.0641 10.4027 17.2873 10.9515 17.5152C11.5002 17.7431 12.0117 17.857 12.486 17.857H12.514C12.9883 17.857 13.4998 17.7431 14.0485 17.5152C14.5973 17.2873 15.0367 17.0641 15.3669 16.8456C15.6971 16.627 16.1272 16.3224 16.6574 15.9318C18.2385 14.7878 20.5543 13.1835 23.6049 11.1187C24.135 10.756 24.6001 10.3514 25 9.90499ZM25 5.80343C25 6.53818 24.7721 7.24037 24.3164 7.91002C23.8607 8.57966 23.2933 9.15165 22.6144 9.62598C19.1174 12.0534 16.941 13.5648 16.0854 14.16C15.9924 14.2251 15.7947 14.367 15.4925 14.5855C15.1902 14.8041 14.9391 14.9808 14.7391 15.1157C14.5392 15.2505 14.2973 15.4016 14.0137 15.5691C13.73 15.7365 13.4626 15.862 13.2115 15.9457C12.9604 16.0294 12.7279 16.0713 12.514 16.0713H12.486C12.2721 16.0713 12.0396 16.0294 11.7885 15.9457C11.5374 15.862 11.27 15.7365 10.9863 15.5691C10.7027 15.4016 10.4608 15.2505 10.2609 15.1157C10.0609 14.9808 9.8098 14.8041 9.50753 14.5855C9.20526 14.367 9.00763 14.2251 8.91462 14.16C8.06827 13.5648 6.84989 12.7161 5.25949 11.614C3.66908 10.5119 2.71577 9.84919 2.39955 9.62598C1.82292 9.23535 1.27883 8.69824 0.767299 8.01465C0.255766 7.33105 0 6.69629 0 6.11035C0 5.38491 0.192987 4.78037 0.578962 4.29674C0.964937 3.8131 1.516 3.57129 2.23214 3.57129H22.7679C23.3724 3.57129 23.8956 3.78985 24.3373 4.22698C24.7791 4.66411 25 5.18959 25 5.80343Z"
                              fill="#403C34"
                            />
                          </svg>
                        </a>
                      </Col>
                    </Row>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className={styles.slide}>
                  <div className={styles.slideText}>
                    <img
                      className={`${styles.quote} img-fluid mt-0`}
                      src={quote}
                      alt="slide 1"
                    />
                    <p style={{ margin: "20px 5px" }}>
                      I like the coffee but a little expensive to recommend to
                      friends. Can buy acceptable equivalent in Waitrose for
                      half the price
                    </p>
                    <img
                      className={`${styles.quote} ${styles.quoteEnd} img-fluid mt-auto mb-0`}
                      src={quote}
                      alt="slide 1"
                    />
                  </div>
                  <div className={styles.clientInfo}>
                    <Row className="mb-4">
                      <p className={styles.name}>Esther Howard</p>
                      <div className={styles.rating}>
                        <img src={star} alt="rating" />
                        <img src={star} alt="rating" />
                        <img src={star} alt="rating" />
                        <img src={star} alt="rating" />
                      </div>
                    </Row>

                    <Row>
                      <Col sm={12} md={3} lg={3}>
                        <svg
                          width="22"
                          height="22"
                          viewBox="0 0 22 22"
                          fill="#403c34"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clipPath="url(#clip0_138_255)">
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M15.6702 7.06023L14.5902 5.72023C14.4202 5.50023 14.3102 5.24023 14.2802 4.95023L14.0902 3.25023C14.0517 2.91078 13.8992 2.59443 13.6576 2.35286C13.416 2.11129 13.0997 1.95878 12.7602 1.92023L11.0602 1.73023C10.7602 1.70023 10.5002 1.57023 10.2802 1.40023L8.94023 0.320234C8.39023 -0.119766 7.61023 -0.119766 7.06023 0.320234L5.72023 1.40023C5.50023 1.57023 5.24023 1.68023 4.95023 1.71023L3.25023 1.90023C2.55023 1.98023 2.00023 2.53023 1.92023 3.23023L1.73023 4.93023C1.70023 5.23023 1.57023 5.49023 1.40023 5.71023L0.320234 7.05023C-0.119766 7.60023 -0.119766 8.38023 0.320234 8.93023L1.40023 10.2702C1.57023 10.4902 1.68023 10.7502 1.71023 11.0402L1.90023 12.7402C1.98023 13.4402 2.53023 13.9902 3.23023 14.0702L4.93023 14.2602C5.23023 14.2902 5.49023 14.4202 5.71023 14.5902L7.05023 15.6702C7.60023 16.1102 8.38023 16.1102 8.93023 15.6702L10.2702 14.5902C10.4902 14.4202 10.7502 14.3102 11.0402 14.2802L12.7402 14.0902C13.4402 14.0102 13.9902 13.4602 14.0702 12.7602L14.2602 11.0602C14.2902 10.7602 14.4202 10.5002 14.5902 10.2802L15.6702 8.94023C16.1102 8.39023 16.1102 7.61023 15.6702 7.06023ZM6.50023 12.0002L3.00023 8.50023L4.50023 7.00023L6.50023 9.00023L11.5002 4.00023L13.0002 5.55023L6.50023 12.0002Z"
                              fill="black"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_138_255">
                              <rect width="16" height="16" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                        <span className={styles.verified}>
                          Verified Customer
                        </span>
                      </Col>
                      <Col sm={12} md={3} lg={3} className={styles.social}>
                        <a
                          className={styles.socialLink}
                          href="https://facebook.com"
                        >
                          <svg
                            width="25"
                            height="25"
                            viewBox="0 0 25 25"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M12.5 1.5C6.425 1.5 1.5 6.425 1.5 12.5C1.5 18.015 5.5625 22.5685 10.856 23.364V15.4155H8.1345V12.524H10.856V10.6C10.856 7.4145 12.408 6.016 15.0555 6.016C16.3235 6.016 16.994 6.11 17.3115 6.153V8.677H15.5055C14.3815 8.677 13.989 9.7425 13.989 10.9435V12.524H17.283L16.836 15.4155H13.989V23.3875C19.358 22.659 23.5 18.0685 23.5 12.5C23.5 6.425 18.575 1.5 12.5 1.5Z"
                              fill="#403C34"
                            />
                          </svg>
                        </a>
                        <a
                          className={styles.socialLink}
                          href="https://facebook.com"
                        >
                          <svg
                            width="25"
                            height="25"
                            viewBox="0 0 25 25"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clipPath="url(#clip0_139_259)">
                              <path
                                d="M25.0312 5.21875C24.1074 5.62891 23.1172 5.9043 22.0762 6.0293C23.1387 5.39258 23.9551 4.38477 24.3379 3.18555C23.3457 3.77344 22.2422 4.20117 21.0723 4.43164C20.1348 3.43164 18.7988 2.80859 17.3203 2.80859C14.4805 2.80859 12.1777 5.10937 12.1777 7.94922C12.1777 8.35156 12.2246 8.74414 12.3125 9.12109C8.03906 8.90625 4.25195 6.85937 1.71484 3.74805C1.27148 4.50977 1.01953 5.39258 1.01953 6.33398C1.01953 8.11719 1.92578 9.69141 3.30664 10.6152C2.46289 10.5879 1.66992 10.3555 0.976562 9.9707C0.976562 9.99219 0.976562 10.0137 0.976562 10.0352C0.976562 12.5273 2.75 14.6035 5.09961 15.0781C4.66992 15.1953 4.21484 15.2578 3.74609 15.2578C3.41406 15.2578 3.0918 15.2266 2.77734 15.1641C3.43359 17.2051 5.33203 18.6953 7.58008 18.7363C5.82227 20.1152 3.60547 20.9355 1.19531 20.9355C0.779297 20.9355 0.371094 20.9121 -0.0292969 20.8633C2.24414 22.3242 4.94727 23.1738 7.85156 23.1738C17.3086 23.1738 22.4805 15.3398 22.4805 8.54688C22.4805 8.32422 22.4746 8.09961 22.4668 7.88086C23.4707 7.15625 24.3418 6.25 25.0312 5.21875Z"
                                fill="#403C34"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_139_259">
                                <rect width="25" height="25" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </a>
                        <a
                          className={styles.socialLink}
                          href="https://facebook.com"
                        >
                          <svg
                            width="25"
                            height="25"
                            viewBox="0 0 25 25"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M25 9.90499V20.982C25 21.5958 24.7814 22.1213 24.3443 22.5585C23.9072 22.9956 23.3817 23.2141 22.7679 23.2141H2.23214C1.6183 23.2141 1.09282 22.9956 0.655692 22.5585C0.218564 22.1213 0 21.5958 0 20.982V9.90499C0.409226 10.3607 0.878906 10.7653 1.40904 11.1187C4.77586 13.4067 7.08705 15.011 8.34263 15.9318C8.87277 16.3224 9.30292 16.627 9.63309 16.8456C9.96326 17.0641 10.4027 17.2873 10.9515 17.5152C11.5002 17.7431 12.0117 17.857 12.486 17.857H12.514C12.9883 17.857 13.4998 17.7431 14.0485 17.5152C14.5973 17.2873 15.0367 17.0641 15.3669 16.8456C15.6971 16.627 16.1272 16.3224 16.6574 15.9318C18.2385 14.7878 20.5543 13.1835 23.6049 11.1187C24.135 10.756 24.6001 10.3514 25 9.90499ZM25 5.80343C25 6.53818 24.7721 7.24037 24.3164 7.91002C23.8607 8.57966 23.2933 9.15165 22.6144 9.62598C19.1174 12.0534 16.941 13.5648 16.0854 14.16C15.9924 14.2251 15.7947 14.367 15.4925 14.5855C15.1902 14.8041 14.9391 14.9808 14.7391 15.1157C14.5392 15.2505 14.2973 15.4016 14.0137 15.5691C13.73 15.7365 13.4626 15.862 13.2115 15.9457C12.9604 16.0294 12.7279 16.0713 12.514 16.0713H12.486C12.2721 16.0713 12.0396 16.0294 11.7885 15.9457C11.5374 15.862 11.27 15.7365 10.9863 15.5691C10.7027 15.4016 10.4608 15.2505 10.2609 15.1157C10.0609 14.9808 9.8098 14.8041 9.50753 14.5855C9.20526 14.367 9.00763 14.2251 8.91462 14.16C8.06827 13.5648 6.84989 12.7161 5.25949 11.614C3.66908 10.5119 2.71577 9.84919 2.39955 9.62598C1.82292 9.23535 1.27883 8.69824 0.767299 8.01465C0.255766 7.33105 0 6.69629 0 6.11035C0 5.38491 0.192987 4.78037 0.578962 4.29674C0.964937 3.8131 1.516 3.57129 2.23214 3.57129H22.7679C23.3724 3.57129 23.8956 3.78985 24.3373 4.22698C24.7791 4.66411 25 5.18959 25 5.80343Z"
                              fill="#403C34"
                            />
                          </svg>
                        </a>
                      </Col>
                    </Row>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </Col>
          <Col sm={12} md={3} lg={3} className="text-center text-md-start">
            <img
              className={`${styles.img} d-none d-sm-block`}
              src={require("../../Assets/Image/coffee1.png")}
              alt="Coffee"
            />
          </Col>
        </Row>
      </Container>
    </motion.div>
  );
}

export default Review;
