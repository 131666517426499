import React from "react";
import Banner from "../../Components/Banner/Banner";
import Quotes from "../../Components/Quotes/Quotes";
import Discount from "../../Components/Discount/Discount";
import About from "../../Components/About/About";
import PopularItems from "../../Components/PopularItems/PopularItems";
import Category from "../../Components/Category/Category";
import OpeningHour from "../../Components/OpeningHour/OpeningHour";
import Review from "../../Components/Review/Review";
import Subscribe from "../../Components/Subscribe/Subscribe";

function Home(props) {
  return (
    <>
      <Banner />
      <Quotes />
      <Discount />
      <About />
      <PopularItems />
      <Category />
      {/* <VideoCase /> */}
      <OpeningHour />
      <Review />
      <Subscribe />
    </>
  );
}

export default Home;
