import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { remove } from "../../Components/App/Slice/CartSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { update } from "../../Components/App/Slice/CartSlice";
import styles from "./Cart.module.css";
const Cart = () => {
  const dispatch = useDispatch();
  const cartItem = useSelector((state) => state.cart);
  const [total, SetTotal] = useState(0);

  useEffect(() => {
    const cartTotal = cartItem.reduce(
      (sum, obj) => sum + obj.qty * obj.price,
      0
    );

    SetTotal(cartTotal);
  }, [cartItem]);
  const clickHandler = (item) => {
    dispatch(remove(item));
  };

  const quantityHandler = (obj, type) => {
    dispatch(update({ obj, type }));
  };
  const submitHandler = (item, total) => {
    const message = document.getElementById("message").value;
    const obj = {
      item,
      message,
      total,
    };
    localStorage.setItem("cart", JSON.stringify(obj));
  };
  return (
    <Container className={styles.cart}>
      <Row>
        <p className={`text-center text-md-start ${styles.heading}`}>
          {" "}
          My Cart
        </p>
      </Row>
      <Row>
        <Col className="my-4" sm={12} md={8}>
          <table className={`  ${styles.table}`}>
            <thead>
              <tr>
                <th className="col-8">Product</th>
                <th className="col-2">Ouantity</th>
                <th className="col-1 text-center">Subtotal</th>
                <th className="col-1 text-end">Remove</th>
              </tr>
            </thead>
            <tbody>
              {cartItem.map((item, index) => {
                return (
                  <tr key={item.id}>
                    <td>
                      <div className="d-flex gap-3">
                        <img
                          className="img-fluid"
                          src={item.image}
                          alt={item.name}
                        />
                        <div>
                          <p className={styles.name}>{item.name}</p>
                          <p className={styles.description}>
                            {item.description}
                          </p>
                          <p className={styles.price}>{`$ ${item.price.toFixed(
                            2
                          )}`}</p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className={styles.quantity}>
                        <span className="ps-3">{item.qty}</span>
                        <div className="d-flex justify-content-between">
                          <button
                            onClick={() => quantityHandler(item, "down")}
                            className={styles.button}
                          >
                            <FontAwesomeIcon icon={faMinus} />
                          </button>
                          <button
                            onClick={() => quantityHandler(item, "up")}
                            className={styles.button}
                          >
                            <FontAwesomeIcon icon={faPlus} />
                          </button>
                        </div>
                      </div>
                    </td>
                    <td className="text-center">{`$ ${(
                      item.price * item.qty
                    ).toFixed(2)}`}</td>
                    <td className="text-end">
                      <button
                        onClick={() => clickHandler(item)}
                        className={styles.button}
                      >
                        <FontAwesomeIcon icon={faXmark} />
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Col>
        <Col className="my-4" sm={12} md={4}>
          <div className={styles.recite}>
            <p>Add Note To Your Order</p>
            <textarea
              id="message"
              className={styles.message}
              rows={6}
              placeholder="message"
            />
            <p className="py-3 m-0">Order Summery</p>
            <div className={styles.info}>
              <div className="d-flex justify-content-between gap-4 my-2">
                <p>Subtotal:</p>
                <p>$ {total.toFixed(2)}</p>
              </div>

              <div className="d-flex justify-content-between gap-4 my-2">
                <p>Shipping:</p>
                <p>1901 Thornridge Cir. Shiloh, Hawaii 81063</p>
              </div>
            </div>
            <div className="d-flex justify-content-between gap-4 mb-4 mt-2">
              <p>Grand Total:</p>
              <p>{`$ ${total.toFixed(2)}`}</p>
            </div>
            <button
              onClick={() => submitHandler(cartItem, total)}
              className="button m-0 w-100 btn-1"
            >
              Process to Checkout
            </button>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Cart;
