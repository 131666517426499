import React from "react";
import { Container, Form } from "react-bootstrap";
import { motion } from "framer-motion";
import styles from "./Subscribe.module.css";
function Subscribe(props) {
  return (
    <Container className={styles.container}>
      <motion.h3
        initial={{ rotateY: 180 }}
        whileInView={{ rotateY: 0 }}
        transition={{ duration: 0.6 }}
        className={styles.heading}
      >
        Want to hear coffee news from us?
      </motion.h3>
      <Form>
        <div className={styles.inputGroup}>
          <motion.input
            initial={{ width: 0 }}
            whileInView={{ width: "35rem" }}
            transition={{ duration: 0.6 }}
            type="email"
            placeholder="Your E-mail Address"
          />

          <button
            type="submit"
            className={`${styles.mail}button btn-1 m-4`}
            style={{ padding: "12px 30px" }}
          >
            Subscribe
          </button>
        </div>
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.8 }}
          className={styles.checkbox}
        >
          <Form.Check style={{ width: "20px" }} />
          <div>
            <span>
              Please read the Coffee Address Personal data processing policy
            </span>
            <a className={styles.policy} href="www.facebook.com">
              here.
            </a>
          </div>
        </motion.div>
      </Form>
    </Container>
  );
}

export default Subscribe;
