import React from "react";
import styles from "./UpComing.module.css";
import { motion } from "framer-motion";
function UpComing(props) {
  const draw = {
    hidden: { pathLength: 0, opacity: 0 },
    visible: (i) => {
      const delay = 1 + i * 0.5;
      return {
        pathLength: 1,
        opacity: 1,
        transition: {
          pathLength: { delay, type: "spring", duration: 1.5, bounce: 0 },
          opacity: { delay, duration: 0.01 },
        },
      };
    },
  };
  return (
    <div className={styles.coming}>
      <svg
        width="170"
        height="225"
        viewBox="0 0 170 225"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_169_255)">
          <path
            d="M107.391 198.62C108.451 198.62 109.081 198.73 109.831 198.81C111.751 199.02 115.221 199.29 116.261 201.23C117.301 203.17 115.161 204.71 113.841 205.63C111.986 206.801 109.998 207.748 107.921 208.45C105.904 209.225 103.848 209.893 101.761 210.45C97.6042 211.525 93.377 212.303 89.1105 212.78C80.5776 213.734 71.9577 213.593 63.4605 212.36C62.4005 212.2 61.3405 212.03 60.2905 211.84C59.2405 211.65 58.1805 211.43 57.1305 211.2C55.0305 210.73 52.9505 210.2 50.8905 209.53C47.4305 208.44 43.1805 207.1 40.6305 204.36C40.1493 203.906 39.8032 203.328 39.6305 202.69C39.5648 202.297 39.598 201.893 39.7272 201.516C39.8564 201.139 40.0775 200.8 40.3705 200.53C41.5377 199.582 42.9482 198.983 44.4405 198.8C47.2411 198.425 50.0652 198.255 52.8905 198.29C49.8205 198.94 45.4505 199.71 43.2705 201.19C42.9005 201.44 42.5005 202.01 42.9205 202.43C43.8409 203.18 44.9281 203.698 46.0905 203.94C48.0294 204.561 50.0027 205.068 52.0005 205.46C56.0063 206.309 60.0558 206.936 64.1305 207.34C68.2105 207.76 72.3005 208.003 76.4005 208.07C80.5005 208.14 84.6105 208.07 88.7005 207.75C96.0516 207.359 103.336 206.152 110.421 204.15C111.191 203.92 114.151 202.91 114.511 202.26C114.741 201.78 114.271 201.14 113.741 200.84C111.699 199.901 109.573 199.157 107.391 198.62Z"
            fill="#2D1613"
          />
          <path
            d="M159.08 199.71C159.134 200.227 159.114 200.749 159.02 201.26C158.19 205.26 154.88 208.02 151.7 210.13C149.845 211.339 147.907 212.415 145.9 213.35C143.9 214.35 141.9 215.13 139.79 215.89C135.663 217.395 131.453 218.66 127.18 219.68C107.99 224.35 88.1799 225.54 68.4999 224.8C58.5195 224.464 48.58 223.357 38.7699 221.49C29.1999 219.64 19.1799 217.31 10.5599 212.64C7.42785 211.07 4.64284 208.887 2.36994 206.22C0.189944 203.44 -0.770056 199.7 0.709944 196.38C0.992866 195.785 1.32749 195.216 1.70994 194.68C2.42531 193.69 3.25805 192.79 4.18994 192C5.08273 191.265 6.02864 190.596 7.01994 190C8.92944 188.828 10.937 187.825 13.0199 187C15.0694 186.191 17.1588 185.487 19.2799 184.89C23.4787 183.727 27.752 182.851 32.0699 182.27C27.9199 183.55 23.8199 184.93 19.8599 186.56C17.8599 187.39 15.9299 188.26 14.0499 189.25C12.1936 190.201 10.4178 191.301 8.73994 192.54C6.62994 194.14 3.84994 196.62 3.86994 199.54C4.03405 201.139 4.79458 202.617 5.99994 203.68C10.7299 208.5 18.2199 210.68 24.5499 212.38C26.5499 212.94 28.6399 213.43 30.6999 213.89C32.7599 214.35 34.8499 214.76 36.9499 215.13C58.8599 219.05 81.5899 219.67 103.77 218.13C107.05 217.91 110.34 217.67 113.61 217.3C117.86 216.85 122.1 216.3 126.31 215.59C130.52 214.88 134.71 214.02 138.83 212.92C139.83 212.64 140.83 212.34 141.9 212.04C142.97 211.74 143.9 211.4 144.9 211.04C147.618 210.148 150.233 208.968 152.7 207.52C155.68 205.74 158.6 202.85 159.08 199.71Z"
            fill="#2D1613"
          />
          <path
            d="M140.5 122.17C140.653 124.457 140.706 126.753 140.66 129.06C140.613 131.367 140.48 133.67 140.26 135.97C140.04 138.27 139.72 140.57 139.32 142.85C138.92 145.13 138.41 147.4 137.82 149.65C137.23 151.9 136.55 154.13 135.82 156.33C135.09 158.53 134.18 160.7 133.22 162.84C131.303 167.127 129 171.231 126.34 175.1C119 185.68 109 194.3 96.8195 198.76C90.9237 200.964 84.6838 202.105 78.3895 202.13C73.5736 202.119 68.782 201.447 64.1495 200.13C59.5377 198.79 55.118 196.86 50.9995 194.39C48.9527 193.174 46.9793 191.838 45.0895 190.39C43.2006 188.953 41.3944 187.411 39.6795 185.77C36.2654 182.492 33.1837 178.884 30.4795 175C27.785 171.144 25.4423 167.055 23.4795 162.78C22.4795 160.65 21.6195 158.49 20.8395 156.29C20.0595 154.09 19.3595 151.87 18.7595 149.63C18.1595 147.39 17.6495 145.11 17.2295 142.83C16.8095 140.55 16.4895 138.25 16.2295 135.95C15.9695 133.65 15.8695 131.35 15.8195 129.04C15.7695 126.73 15.8195 124.44 15.9695 122.15C16.1195 124.44 16.3195 126.72 16.6095 128.99C16.8995 131.26 17.2495 133.51 17.6795 135.74C18.1095 137.97 18.6195 140.19 19.2095 142.38C19.7995 144.57 20.4495 146.74 21.2095 148.87C21.9695 151 22.7595 153.11 23.6495 155.17C24.5395 157.23 25.5095 159.27 26.5595 161.25C28.6444 165.211 31.0561 168.99 33.7695 172.55C36.4562 176.085 39.4695 179.359 42.7695 182.33C46.0427 185.276 49.6233 187.861 53.4495 190.04C57.2435 192.199 61.2912 193.879 65.4995 195.04C72.0407 196.8 78.8717 197.21 85.5764 196.246C92.2812 195.281 98.7196 192.962 104.5 189.43C107.843 187.393 110.99 185.049 113.9 182.43C117.206 179.452 120.22 176.163 122.9 172.61C125.584 169.033 127.969 165.24 130.03 161.27C131.03 159.27 132.03 157.27 132.9 155.17C133.77 153.07 134.58 150.99 135.31 148.85C136.762 144.552 137.915 140.158 138.76 135.7C139.18 133.47 139.54 131.22 139.82 128.95C140.1 126.68 140.35 124.46 140.5 122.17Z"
            fill="#2D1613"
          />
          <path
            d="M140.66 118.3C140.148 120.391 138.884 122.221 137.11 123.44C133.68 125.98 129.11 127.44 125.11 128.67C120.71 129.934 116.239 130.936 111.72 131.67C96.9727 134.11 82.0155 135.041 67.0797 134.45C50.5697 133.89 28.7397 131.35 21.5897 126.96C19.8763 126.042 18.4033 124.733 17.2897 123.14C15.2897 119.84 16.9097 116.43 19.5697 114.14C22.1469 112.026 25.0607 110.359 28.1897 109.21C32.2778 107.638 36.4978 106.433 40.7997 105.61C42.9197 105.19 45.0597 104.81 47.1997 104.51C49.3397 104.21 51.4797 103.94 53.6197 103.72C68.5805 102.187 83.6589 102.187 98.6197 103.72C100.75 103.94 102.88 104.18 105 104.49C106.07 104.63 107.12 104.8 108.18 104.98C109.24 105.16 110.29 105.36 111.34 105.58C112.39 105.8 113.43 106.04 114.47 106.31C115.51 106.58 116.53 106.9 117.53 107.31C115.469 106.759 113.374 106.342 111.26 106.06C109.15 105.76 107.04 105.53 104.91 105.35C102.78 105.17 100.66 105.03 98.5397 104.94C83.6691 104.205 68.7625 104.807 53.9997 106.74C48.8295 107.391 43.7058 108.369 38.6597 109.67C33.7297 110.99 28.5097 112.56 24.1997 115.35C23.1092 116.057 22.1028 116.886 21.1997 117.82C20.9294 118.028 20.7047 118.289 20.5395 118.587C20.3743 118.886 20.2722 119.215 20.2394 119.554C20.2065 119.893 20.2438 120.236 20.3488 120.56C20.4538 120.885 20.6243 121.184 20.8497 121.44C22.1597 123.2 24.3997 124.23 26.3697 125.04C27.5697 125.52 28.8197 125.94 30.0897 126.32C32.6665 127.056 35.2801 127.657 37.9197 128.12C52.5097 130.73 67.7897 131.19 82.6097 131.12C89.4997 131.12 96.4297 130.97 103.3 130.34C113.95 129.5 125.17 128.29 135.02 123.87C136.303 123.342 137.505 122.635 138.59 121.77C139.65 120.879 140.379 119.656 140.66 118.3Z"
            fill="#2D1613"
          />
          <path
            d="M142.349 131C143.207 130.466 144.095 129.982 145.009 129.55C145.929 129.11 146.869 128.71 147.819 128.35C148.769 127.99 149.759 127.67 150.759 127.35C154.369 126.35 158.439 126.05 161.999 127.46C167.409 129.6 170.239 135.33 169.999 140.95C169.729 146.88 166.729 152.51 163.209 157.15C161.854 158.927 160.367 160.598 158.759 162.15C154.811 165.887 150.473 169.19 145.819 172C142.359 174.118 138.776 176.028 135.089 177.72C133.249 178.56 131.389 179.347 129.509 180.08C127.629 180.81 125.719 181.49 123.779 182.08L128.849 178.82C130.519 177.73 132.189 176.63 133.849 175.52C137.149 173.29 140.379 171.01 143.519 168.63C148.78 164.764 153.602 160.335 157.899 155.42C161.709 150.9 165.119 145.31 164.999 139.17C165.037 137.757 164.76 136.353 164.189 135.059C163.619 133.766 162.768 132.615 161.699 131.69C159.871 130.335 157.682 129.552 155.409 129.44C152.51 129.296 149.604 129.514 146.759 130.09C146.269 130.17 145.759 130.27 145.289 130.37C144.299 130.53 143.319 130.76 142.349 131Z"
            fill="#2D1613"
          />
          <path
            d="M30.5395 143C30.5395 143 38.2495 176.57 71.9995 192.7C71.9995 192.7 31.7995 194.28 19.6895 131.34C19.6895 131.34 30.8694 139.21 80.4194 137.98C80.4494 138 62.1195 145.63 30.5395 143Z"
            fill="#2D1613"
          />
          <path
            d="M123.449 140.35C122.997 140.771 122.588 141.237 122.229 141.74L120.789 143.36C119.849 144.42 118.919 145.48 118.009 146.56C116.142 148.585 114.502 150.808 113.119 153.19C112.899 153.61 112.699 154.1 112.869 154.19C113.329 154.52 114.379 154.03 114.869 153.92C115.959 153.64 117.019 153.26 118.099 152.92C119.699 152.44 121.609 151.97 123.239 152.58C123.984 152.84 124.612 153.358 125.009 154.04C125.354 154.747 125.483 155.54 125.379 156.32C125.069 159.67 122.789 162.6 120.799 165.16C118.675 167.916 116.334 170.497 113.799 172.88C112.449 174.15 111.059 175.38 109.609 176.53C108.499 177.41 107.369 178.26 106.199 179.04C105.033 179.84 103.81 180.552 102.539 181.17C104.654 179.363 106.658 177.43 108.539 175.38C111.026 172.713 113.399 169.97 115.659 167.15C117.865 164.539 119.78 161.696 121.369 158.67C121.519 158.36 121.659 158.05 121.789 157.73C121.919 157.41 122.619 155.93 122.199 155.57C122.069 155.482 121.916 155.437 121.759 155.44C120.945 155.415 120.132 155.516 119.349 155.74C117.179 156.27 115.129 157.12 112.839 157.01C112.193 157.021 111.559 156.836 111.019 156.48C109.779 155.56 110.019 153.9 110.609 152.7C112.029 149.9 114.609 147.61 116.889 145.56C117.889 144.62 119.019 143.71 120.119 142.84C121.219 141.97 122.289 141.15 123.449 140.35Z"
            fill="#2D1613"
          />
          <path
            d="M78.1898 129.76C106.412 129.76 129.29 125.789 129.29 120.89C129.29 115.991 106.412 112.02 78.1898 112.02C49.9681 112.02 27.0898 115.991 27.0898 120.89C27.0898 125.789 49.9681 129.76 78.1898 129.76Z"
            fill="#2D1613"
          />
          <path
            d="M125.561 183.68C127.025 183.58 128.494 183.56 129.961 183.62C131.431 183.68 132.901 183.81 134.361 184C137.289 184.374 140.18 185 143.001 185.87L144.051 186.22L145.051 186.59L146.051 186.99L147.051 187.41C148.428 187.985 149.764 188.654 151.051 189.41C152.332 190.173 153.534 191.061 154.641 192.06C155.751 193.045 156.709 194.191 157.481 195.46C156.469 194.419 155.357 193.482 154.161 192.66C152.975 191.856 151.735 191.134 150.451 190.5C147.871 189.241 145.196 188.188 142.451 187.35C139.701 186.49 136.901 185.79 134.071 185.19C131.241 184.59 128.461 184.13 125.561 183.68Z"
            fill="#2D1613"
          />
          <motion.path
            initial={{ opacity: 1, pathLength: 0, offsetPath: 1 }}
            animate={{ opacity: 1, pathLength: 1, offsetPath: 0 }}
            transition={{ duration: 3, repeat: Infinity, delay: 1 }}
            stroke="#2D1613"
            strokeWidth={3}
            strokeLinecap="inherit"
            fill="transparent"
            d="M111.769 101.45C111.26 101.327 110.759 101.173 110.269 100.99C109.779 100.81 109.269 100.61 108.839 100.39C106.656 99.4677 104.708 98.0684 103.137 96.2947C101.566 94.521 100.411 92.418 99.7592 90.14C96.7592 78.77 108.079 70.14 116.339 62.44C124.599 54.74 132.939 45.13 131.169 34.26C131.039 33.43 130.839 32.59 130.659 31.74C130.769 32.24 131.949 33.12 132.309 33.58C132.908 34.2982 133.437 35.0717 133.889 35.89C134.686 37.3655 135.265 38.9486 135.609 40.59C136.27 44.0746 135.97 47.6735 134.739 51C132.919 56.19 129.539 60.93 125.539 65.2C121.539 69.47 116.649 73 112.539 77.2C106.759 83 102.999 90.42 108.099 97.48C108.607 98.1828 109.162 98.851 109.759 99.48C110.383 100.182 111.055 100.84 111.769 101.45Z"
          />
          <motion.path
            initial={{ opacity: 1, pathLength: 0, offsetPath: 1 }}
            animate={{ opacity: 1, pathLength: 1, offsetPath: 0 }}
            transition={{ duration: 3, repeat: Infinity, delay: 1 }}
            stroke="#2D1613"
            strokeWidth={3}
            strokeLinecap="inherit"
            fill="transparent"
            d="M50.5608 101C50.1814 100.844 49.8109 100.667 49.4508 100.47C49.0808 100.27 48.7308 100.06 48.3908 99.84C47.6892 99.4141 47.0207 98.9361 46.3908 98.41C37.0408 90.56 39.8708 78.29 47.6608 70.41C49.9708 68.08 52.2808 65.73 54.5508 63.41C57.3909 60.658 59.9656 57.6447 62.2408 54.41C63.9508 51.75 65.2408 48.66 64.0408 45.72C63.7588 45.0673 63.4244 44.4386 63.0408 43.84C63.8533 44.128 64.6261 44.5178 65.3408 45C69.7208 48 69.5508 53.5 68.1308 57.6C65.6308 64.81 59.6508 70.46 54.0008 76.16C52.7091 77.4533 51.5189 78.844 50.4408 80.32C48.3465 83.0545 47.1016 86.344 46.8608 89.78C46.7967 92.3893 47.341 94.9776 48.4508 97.34C49.0326 98.6261 49.7393 99.852 50.5608 101Z"
          />
          <motion.path
            initial={{ opacity: 1, pathLength: 0, offsetPath: 1 }}
            animate={{ opacity: 1, pathLength: 1, offsetPath: 0 }}
            transition={{ duration: 3, repeat: Infinity }}
            stroke="#2D1613"
            strokeWidth={3}
            strokeLinecap="inherit"
            fill="transparent"
            d="M88.3093 101.45C87.9393 101.37 87.5893 101.26 87.2293 101.15L86.1693 100.77C85.468 100.508 84.7804 100.211 84.1093 99.88C72.7893 94.39 69.6893 83.14 72.8693 73.27C73.4139 71.557 74.0823 69.886 74.8693 68.27C79.7893 58.22 89.2093 50.33 97.0093 41.78C102.309 35.97 107.429 29.33 110.129 22.38C112.129 17.24 112.569 11.52 110.129 6.38C109.032 4.10107 107.669 1.9596 106.069 0C108.687 1.22793 111.088 2.87265 113.179 4.87C114.201 5.86307 115.125 6.95133 115.939 8.12C118.353 11.7576 119.573 16.0569 119.429 20.42C119.209 31.52 112.509 41.71 104.499 50.35C98.4993 56.84 91.6993 62.85 86.4993 69.83C83.6393 73.69 81.1393 77.83 80.2393 82.29C79.0893 88.17 80.9993 94 85.3693 98.72C85.8093 99.2 86.2793 99.66 86.7593 100.12L87.5193 100.8C87.7693 101 87.9993 101.24 88.3093 101.45Z"
          />
        </g>
        <defs>
          <clipPath id="clip0_169_255">
            <rect width="170" height="225" fill="white" />
          </clipPath>
        </defs>
      </svg>
      <h3 className="text-center mt-4">Coming Soon...</h3>
    </div>
  );
}

export default UpComing;
