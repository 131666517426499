import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isError: false,
  message: "",
};
const errorSlice = createSlice({
  name: "error",
  initialState,
  reducers: {
    setError(state, action) {
      state.isError = true;
      state.message = action.payload;
    },
    removeError(state) {
      state.pop();
    },
  },
});
export default errorSlice.reducer;
export const { setError, removeError } = errorSlice.actions;
