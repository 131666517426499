import React from "react";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import { NavLink } from "react-router-dom";
import styles from "./SideBar.module.css";
function SideBar(props) {
  return (
    <Drawer open={props.open} onClose={props.onClose} direction="left">
      <div className={styles.sideBar}>
        <nav className={styles.container}>
          <NavLink className={styles.navItem} to="/">
            Home
          </NavLink>
          <NavLink className={styles.navItem} to="/about">
            About Us
          </NavLink>
          <NavLink className={`${styles.navItem}`} to="/drinks">
            Coffee & Drinks
          </NavLink>
          <NavLink className={`${styles.navItem}`} to="/foods">
            Food Menu
          </NavLink>
          <NavLink className={`${styles.navItem}`} to="/reservation">
            Reservation
          </NavLink>
          <NavLink className={`${styles.navItem}`} to="/contact">
            Contact Us
          </NavLink>
        </nav>
      </div>
      <p className="text-center">All Rights Reserved @2023</p>
    </Drawer>
  );
}

export default SideBar;
