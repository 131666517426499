import React from "react";
import { useParams } from "react-router-dom";
import Login from "../../Components/Authentication/Login";
import Register from "../../Components/Authentication/Register";
export const Authenticate = () => {
  const { path } = useParams();

  if (path === "login") {
    return <Login />;
  }
  if (path === "register") {
    return <Register />;
  }
};
export default Authenticate;
