// Time and Date Formatter
export const formatter = (a, b) => {
  const dateValue = a;
  const timeValue = b;

  const [year, month, day] = dateValue.split("-");
  const [hours, minutes] = timeValue.split(":");

  const date = new Date(year, month - 1, day);
  const time = new Date();
  time.setHours(hours);
  time.setMinutes(minutes);

  const timeFormatted = time.toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });

  const dateFormatted = date.toLocaleString("en-US", {
    weekday: "long",
    month: "long",
    day: "numeric",
  });
  return { dateFormatted, timeFormatted };
};
