import React from "react";
import { Col, Row } from "react-bootstrap";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartShopping } from "@fortawesome/free-solid-svg-icons";
import styles from "./ItemCard.module.css";
import coffee from "../../../Assets/Image/coldCoffee.png";
import star from "../../../Assets/Image/star.svg";
import { useDispatch } from "react-redux";
import { add } from "../../App/Slice/CartSlice";
function ItemCard({ product }) {
  const data = product;
  const dispatch = useDispatch();

  const btnHandler = (item) => {
    const data = { ...item, qty: 1 };
    dispatch(add(data));
  };
  return (
    <motion.div
      initial={{ y: 120 }}
      whileInView={{ y: 0 }}
      transition={{ duration: 0.6, type: "spring", stiffness: 90 }}
      className={styles.card}
    >
      <Row>
        <Col className="text-start">
          <div>
            <span className={styles.price}>{`$ ${data?.price}`}</span>
          </div>
          <div className={styles.rating}>
            <img src={star} alt="rating" />
            <img src={star} alt="rating" />
            <img src={star} alt="rating" />
            <img src={star} alt="rating" />
          </div>
        </Col>
        <Col>
          <img className={styles.img} src={data.image || coffee} alt="coffee" />
        </Col>
      </Row>
      <h3 className={styles.name}>{data?.name}</h3>
      <p className={styles.ingrident}>{data?.description}</p>
      <Row>
        <Col sm={7}>
          <p className={styles.discount}>
            <span>10%</span> Off for new Customer
          </p>
        </Col>
        <Col sm={5}>
          <motion.button
            whileHover={{ scale: 1.09 }}
            transition={{ duration: 0.1, type: "spring", stiffness: 440 }}
            className={styles.button}
            onClick={() => btnHandler(data)}
          >
            {" "}
            <FontAwesomeIcon icon={faCartShopping} /> add
          </motion.button>
        </Col>
      </Row>
    </motion.div>
  );
}

export default ItemCard;
