import React from "react";
import styles from "./Category.module.css";
import { Container, Row } from "react-bootstrap";
import { motion } from "framer-motion";
import CategoryCard from "../UiElement/CategoryCard/CategoryCard";
export default function Category() {
  return (
    <Container className={styles.category}>
      <Row>
        <motion.h4
          initial={{ scale: 0 }}
          whileInView={{ scale: 1 }}
          transition={{ duration: 0.6, type: "spring", stiffness: 90 }}
          className={`${styles.heading} `}
        >
          Our Category
        </motion.h4>
      </Row>
      <Row>
        <div className={`${styles.content}`}>
          <motion.p
            initial={{ translateX: 189 }}
            whileInView={{ translateX: 0 }}
            className={`${styles.title} ps-4 text-center text-md-start`}
          >
            Coffee
          </motion.p>
          <Row>
            <div className={styles.cards}>
              <CategoryCard
                title="Espresso"
                img={require("../../Assets/Image/coldCoffee.png")}
              />
              <CategoryCard
                title="Espresso"
                img={require("../../Assets/Image/coldCoffee.png")}
              />
              <CategoryCard
                title="Espresso"
                img={require("../../Assets/Image/coldCoffee.png")}
              />
            </div>
          </Row>
        </div>
      </Row>
      <Row>
        <div className={`${styles.content}`}>
          <motion.p
            initial={{ translateX: 189 }}
            whileInView={{ translateX: 0 }}
            className={`${styles.title} text-center text-md-start ps-4`}
          >
            Food
          </motion.p>
          <Row>
            <div className={styles.cards}>
              <CategoryCard
                title="Meat Lovers"
                img={require("../../Assets/Image/meat.png")}
              />
              <CategoryCard
                title="Sides"
                img={require("../../Assets/Image/sides.png")}
              />
              <CategoryCard
                title="Chicken Items"
                img={require("../../Assets/Image/chicken.png")}
              />
            </div>
          </Row>
        </div>
      </Row>
      <Row>
        <div className={`${styles.content}`}>
          <motion.p
            initial={{ translateX: 189 }}
            whileInView={{ translateX: 0 }}
            className={`${styles.title} text-center text-md-start ps-4`}
          >
            Drinks
          </motion.p>
          <Row>
            <div className={styles.cards}>
              <CategoryCard
                title="Frozen Lemonade"
                img={require("../../Assets/Image/lemonade.png")}
              />
              <CategoryCard
                title="Smoothies"
                img={require("../../Assets/Image/smoothies.png")}
              />
              <CategoryCard
                title="Protien Shakes"
                img={require("../../Assets/Image/protein.png")}
              />
            </div>
          </Row>
        </div>
      </Row>
    </Container>
  );
}
