import React from "react";
import { motion } from "framer-motion";
import styles from "./Card1.module.css";
import discount from "../../../Assets/Image/discount.jpg";
function Card1(props) {
  return (
    <motion.div
      initial={{ y: -50, opacity: 0 }}
      whileInView={{ y: 0, opacity: 1 }}
      transition={{ duration: 0.8, type: "spring" }}
      className={`${styles.card}`}
    >
      <img className={styles.image} src={discount} alt="Discount" />
      <div className={styles.overlay}></div>
      <p className={`${styles.heading}`}>Drink coffee and keep writing.</p>
      <p className={`${styles.title}`}>Welcome to our Cafe</p>
      <p className={`${styles.text}`}>Get 10% Discount for Office Coffee</p>
    </motion.div>
  );
}

export default Card1;
