import React from "react";
import { motion } from "framer-motion";
import styles from "./CategoryCard.module.css";
export default function CategoryCard(props) {
  return (
    <motion.div
      initial={{ opacity: 0, y: -40 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.4, delay: 0.2, type: "spring", stiffness: 80 }}
      className={styles.card}
    >
      <div className={styles.content}>
        <img className="img-fluid" src={props.img} alt="Food" />
        <p>{props.title}</p>
      </div>
    </motion.div>
  );
}
