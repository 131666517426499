import React from "react";
import { Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationDot,
  faClockRotateLeft,
} from "@fortawesome/free-solid-svg-icons";
import styles from "./TopBar.module.css";
function TopBar(props) {
  return (
    <div className={`${styles.headerTop} d-none d-lg-block`}>
      <Container className={`${styles.headerItems}`}>
        <div className={`${styles.headerInfo}`}>
          <span className={styles.headerText}>
            Sign up for our Loyalty Program today and receive 15% OFF your first
            order.
          </span>
        </div>
        <div className={`${styles.headerInfo}`}>
          <div className={styles.headerText}>
            <span className={styles.headerIcon}>Need help?</span>
            <span>(+302) 555-0107-122</span>
          </div>
          <div className={styles.headerText}>
            <span className={styles.headerIcon}>
              <FontAwesomeIcon icon={faClockRotateLeft} size={"lg"} />
            </span>
            <span>08:00am - 10:00pm</span>
          </div>
          <div className={styles.headerText}>
            <span className={styles.headerIcon}>
              <FontAwesomeIcon icon={faLocationDot} size={"lg"} />
            </span>
            <span>Our Locations</span>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default TopBar;
