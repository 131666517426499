import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { motion } from "framer-motion";
import coffee from "../../Assets/Image/coldCoffee.png";
import styles from "./About.module.css";
function About(props) {
  const [active, setActive] = useState("1");
  const clickHandler = (e) => {
    document.getElementById(active).removeAttribute("style");
    const id = e.target.parentElement.id;
    document.getElementById(`${id}`).style.width = "35vw";
    setActive(id);
  };
  return (
    <div className={styles.contain}>
      <Container className={styles.container}>
        <Row>
          <motion.div
            initial={{ width: "0%" }}
            whileInView={{ width: "100%" }}
            transition={{ duration: 0.6, type: "spring", stiffness: 80 }}
            id="card"
            className={styles.about}
          >
            <div
              id="1"
              onClick={clickHandler}
              className={styles.card}
              style={{ width: "50%" }}
            >
              <img
                className={styles.img}
                src="https://source.unsplash.com/random/900x600?restaurant"
                alt="restaurant"
              />
            </div>
            <div id="2" onClick={clickHandler} className={styles.card}>
              <img
                className={styles.img}
                src="https://source.unsplash.com/random/901x600?restaurant"
                alt="restaurant"
              />
            </div>
            <div id="3" onClick={clickHandler} className={styles.card}>
              <img
                className={styles.img}
                src="https://source.unsplash.com/random/900x601?restaurant"
                alt="restaurant"
              />
            </div>
          </motion.div>
        </Row>
        <Row>
          <Col className={styles.texts} sm={12} md={8} lg={8}>
            <motion.div
              initial={{ x: 80 }}
              whileInView={{ x: 0 }}
              transition={{ duration: 0.6 }}
            >
              <h3 className={styles.title}>
                We’re a dedicated gluten-free café.
              </h3>
              <p className={styles.subTitle}>
                But you’d never know if we didn’t mention it.
              </p>
              <p className={styles.desc}>
                You see, our creations redefine what gluten-free means.
                Impossibly moist layer cakes, decadent desserts, pillowy rolls,
                tender crumbs—everything we make is a reflection of our
                commitment to flavor, texture and artistry of the highest
                caliber. Informed by classical French pastry technique and
                inspired by California’s spirit of creativity, we bake because
                life’s too short for anyone to skip cake.
              </p>
              <motion.button
                whileHover={{ scale: 1.09 }}
                transition={{ duration: 0.1, type: "spring", stiffness: 440 }}
                className="button btn-1"
              >
                Read More
              </motion.button>
            </motion.div>
          </Col>
          <Col sm={12} md={4} lg={4} className={styles.coffee}>
            <motion.div
              initial={{ scale: 0, y: 60 }}
              whileInView={{ scale: 1, y: 0 }}
              transition={{ duration: 0.6, type: "spring", stiffness: 80 }}
            >
              <img className={`${styles.img}`} src={coffee} alt="Coffee" />
            </motion.div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default About;
