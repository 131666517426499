import { Route, Routes } from "react-router-dom";
import "./App.css";
import Footer from "./Components/Footer/Footer";
import NavBar from "./Components/NavBar/NavBar";
import TopBar from "./Components/TopBar/TopBar";
import Home from "./Page/Home/Home";
import SideBar from "./Components/SideBar/SideBar";
import { useState } from "react";
import AboutPage from "./Page/About/AboutPage";
import UpComing from "./Page/UpComing/UpComing";
import ContactPage from "./Page/Contact/ContactPage";
import FoodPage from "./Page/FoodPage/FoodPage";
import DrinkPage from "./Page/DrinkPage/DrinkPage";
import Authenticate from "./Page/Authenticate/Authneticate";
import PwReset from "./Page/PwReset/PwReset";
import Cart from "./Page/Cart/Cart";
import Reservation from "./Page/Reservation/Reservation";
function App() {
  const [isOpen, setIsOpen] = useState(false);
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };
  return (
    <div className="App">
      <TopBar />
      <NavBar drawer={toggleDrawer} open={isOpen} />
      <SideBar open={isOpen} onClose={toggleDrawer} />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/drinks" element={<DrinkPage />} />
        <Route path="/foods" element={<FoodPage />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/reservation" element={<Reservation />} />
        <Route path="/authenticate/:path" element={<Authenticate />} />
        <Route path="/recover" element={<PwReset />} />
        <Route path="/*" element={<UpComing />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
