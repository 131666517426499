import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { motion } from "framer-motion";
import ItemCard from "../UiElement/ItemCard/ItemCard";
import styles from "./PopularItems.module.css";
import { Link } from "react-router-dom";
import { foods } from "../App/data";
function PopularItems(props) {
  const data = foods;
  return (
    <>
      <Container className={styles.popularItem}>
        <Row>
          <motion.h3
            initial={{ scale: 0 }}
            whileInView={{ scale: 1 }}
            transition={{ duration: 0.6, type: "spring", stiffness: 90 }}
            className={styles.heading}
          >
            Our Most Popular Coffee
          </motion.h3>
        </Row>
        <Row className={styles.content}>
          {data?.coffee.slice(0, 3).map((item, index) => {
            return (
              <Col key={index} sm={12} md={4} lg={4}>
                <ItemCard product={item} />
              </Col>
            );
          })}
        </Row>
      </Container>
      <Container className={styles.popularItem}>
        <Row>
          <motion.h3
            initial={{ scale: 0 }}
            whileInView={{ scale: 1 }}
            transition={{ duration: 0.6, type: "spring", stiffness: 90 }}
            className={styles.heading}
          >
            Our Foods for Foodies
          </motion.h3>
          <motion.p
            initial={{ y: 40, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.6, type: "spring", stiffness: 90 }}
            className={styles.title}
          >
            We collect the best quality of ingredients From market. With the
            best execution we provide our guest fresh & very Delicious Food.
          </motion.p>
        </Row>
        <Row className={styles.content}>
          {data?.pizza.slice(0, 6).map((item, index) => {
            return (
              <Col key={index} sm={12} md={4} lg={4}>
                <ItemCard product={item} />
              </Col>
            );
          })}
        </Row>
        <Row className={styles.content}>
          <Row className="pt-4">
            <Link to="/foods">
              <motion.button
                whileHover={{ scale: 1.09 }}
                transition={{ duration: 0.1, type: "spring", stiffness: 440 }}
                className="button btn-1 mx-auto"
              >
                See More
              </motion.button>
            </Link>
          </Row>
        </Row>
      </Container>
    </>
  );
}

export default PopularItems;
