import { createSlice } from "@reduxjs/toolkit";

const isObjInCart = (state, id) => {
  return state.find((obj) => obj.id === id);
};

const cartSlice = createSlice({
  name: "cart",
  initialState: [],
  reducers: {
    add(state, action) {
      if (!isObjInCart(state, action.payload.id)) {
        state.push(action.payload);
      } else {
        console.log("already in cart");
      }
    },

    update(state, action) {
      const { obj, type } = action.payload;
      const index = state.findIndex((item) => item.id === obj.id);
      if (type === "up") {
        state[index].qty += 1;
      }
      if (type === "down" && state[index].qty > 1) {
        state[index].qty += -1;
      }
    },
    remove(state, action) {
      return state.filter((item) => item.id !== action.payload.id);
    },
  },
});
export default cartSlice.reducer;
export const { add, remove, update } = cartSlice.actions;
